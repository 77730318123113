import AppConfig from '../config';
import { CardTypes } from './ActionType';
import { getAxios } from './setAuthorizationToken';

const API_BASE_URL = AppConfig.API_BASE_URL;

export const saveNewCard = (card) => {
    return function(dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/Payment/AddCard`, card)
            .then((res) => {
                if (res && res.data && res.data.id) {
                    dispatch(getCards());
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: "SUCCESS", message: "Card added successfully" } })
                    return res.data.id;
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: "ERROR", message: res.data.message } })    
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: "ERROR", message: 'Failed to save card.' } })
                return false;
            });
    }
}

export const deleteCard = (id) => {
    return function(dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/Payment/DeleteCard/${id}`)
            .then((res) => {
                dispatch(getCards());
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: "ERROR", message: 'Failed to delete card.' } })
                return false;
            });
    }
}

export const getCards = () => {
    return function(dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/Payment/GetCards`)
            .then((res) => {
                if (res && res.data && res.data.length > 0) {
                    dispatch({ type: CardTypes.LOAD_CARDS, payload: res.data });
                } else {
                    dispatch({ type: CardTypes.LOAD_CARDS, payload: [] });
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: "ERROR", message: 'Failed to load cards.' } })
                return false;
            });
    }
}