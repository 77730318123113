import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import './BundlesDetailedPage.scss';
import '../FurnitureDetailedPage/FurnitureDetailedPage.scss'
import { Row, Col, Button, Spin } from 'antd';
import BreadCrumbs from '../../Components/BreadCrumbs/BreadCrumbs';
import ProductSldier from './ProductSldier/ProductSlider';
import InputBox from '../../Components/InputBox/InputBox';
import GetRefund from './GetRefund/GetRefund';
import RecommendedProducts from './RecommendedProducts/RecommendedProducts';
import SimilarProducts from './SimilarProducts/SimilarProducts';
import { getBundleListing, getBundleSubGroups } from '../../Actions/BundleActions';
import { addInspirationsToCart, addItemToCart, addItemToTempCart } from '../../Actions/CartActions';
import { updateItemsInWishList } from '../../Actions/WishListActions'
import SelectBox from '../../Components/SelectBox/SelectBox';
import { getFurnitureInfo, getAlternateProducts } from '../../Actions/FurnitureActions';
import FurnitureSlider from '../FurnitureDetailedPage/FurnitureSlider/FurnitureSlider';

class BundlesDetailedPage extends React.Component {
    constructor() {
        super()
        this.state = {
            zipCode: '',
            item: undefined,
            room: '',
            newroom: '',
            quantity: 1
        }
    }
    componentDidMount() {
        if(this.props.match.params.bundleid) {
            getFurnitureInfo(this.props.match.params.bundleid).then(items => {
                const item = items && items.length > 0 ? items[0] : undefined;
                this.setState({ item });
            })
            this.props.dispatch(getAlternateProducts(this.props.match.params.bundleid, 1));
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }

    onChangeQuantity = (value, type) => {
        this.props.dispatch(getAlternateProducts(this.props.match.params.bundleid, value));
        this.setState({ [type]: value })
    }

    addToCart = () => {
        const model = {
            "matCode": this.state.item.maT_CODE,
            "quantity": 1,
            "room": this.state.room === 'Neeeew' ? this.state.newroom : this.state.room
        }
        if (this.props.context) {
            this.props.dispatch(addItemToCart(model))
        } else {
            this.props.dispatch(addItemToTempCart(this.state.item, model.quantity, model.room));
        }
        this.goBack()
    }

    addToWishList = () => {
        const model = {
            "matCode": this.state.item.maT_CODE,
        }

        this.props.dispatch(updateItemsInWishList(model))
        this.goBack()
    }

    addWithBedSetup = () => {
        const alternateProducts = this.props.alternateProducts;
        const items = alternateProducts.map(product => {
            return {
                "matCode": product.alternateProduct,
                "quantity": product.qty,
                "room": this.state.room === 'Neeeew' ? this.state.newroom : this.state.room
            }
        });
        items.push({
            "matCode": this.state.item.maT_CODE,
            "quantity": parseInt(this.state.quantity),
            "room": this.state.room === 'Neeeew' ? this.state.newroom : this.state.room
        })
        this.props.dispatch(addInspirationsToCart({ "inspirationProducts" : items })).then(() => {
            this.goBack()
        })
    }

    goBack = () => {
        this.props.history.push(`/bundles-listing/${this.state.item.matgrouP_CODE}/${this.state.item.matgrouP_DESC}`)
    }

    render() {
        if(this.state.loading || !this.state.item) {
            return <Spin tip={"Bundle is loading. Please wait.."} />
        }
        const quantityOptions = [];
        for(let i=1; i<=this.state.item.quantity; i++) {
            quantityOptions.push({ label: i, value: i })
        }
        return (
            <React.Fragment>

                {/* page banners */}
                <section className="page-banners bundles">
                    <div className="container mx-auto">
                        <h1>Bundles</h1>
                        <p>Whether you're looking for bespoke luxury furniture in an Art Deco style or a beautifully handcrafted modern sofa, with our tailored selection of designer furniture from the world’s leading designer brands, we have a style to suit every home interior.</p>
                    </div>
                </section>
                {/* page banners */}

                <section className="inner-pages top-right-bottom-wave light-bg">
                    <div className="container mx-auto">

                        <BreadCrumbs {...this.props} navigations={[{ name: 'Home', link: '/' }, { name: 'Bundles', link: '/bundles' }, { name: 'Choose by Room Type', link: '/bundles' }, { name: this.state.item.matgrouP_DESC, link: `/bundles-listing/${this.state.item.matgrouP_CODE}/${this.state.item.matgrouP_DESC}` }, { name: this.state.item.maT_DESC, link: '/bundles-detailed-page' }]} />

                        <Row gutter={[{ xs: 8, sm: 20, md: 30, lg: 40 }, { xs: 8, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                                { this.state.item && <FurnitureSlider image={this.state.item.imagePath} batch={this.state.item.batch ? this.state.item.batch : [] }  /> }
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2 }}>
                                <div className="detailed-view furniture-select-fields">
                                    <div className="title">
                                        <h2>{this.state.item.maT_DESC}</h2>
                                        {
                                            !this.state.item.showavailabilty && <span style={{ color: 'red' }}>Out of stock</span>
                                        }
                                        <p>{this.state.item.remark1}</p>
                                    </div>
                                    <div className="price">$ {this.state.item.monthlyrent}/m</div>
                                    <div className="overview">
                                        <ul>
                                            {
                                                this.state.item.batch && this.state.item.batch.map(item => <li>{item.qty}  X  {item.maT_DESC}</li>)
                                            }
                                        </ul>
                                    </div>
                                    <div className="quantity">
                                            <div className="form-fields">
                                                <SelectBox
                                                    disabled={!this.state.item.showavailabilty}
                                                    label="Quantity"
                                                    id="quantity"
                                                    placeholder={"Choose"}
                                                    value={this.state.quantity ? this.state.quantity : 1}
                                                    options={quantityOptions}
                                                    onChangeText={this.onChangeQuantity}
                                                    optional="true"
                                                />
                                            </div>
                                        </div>
                                    <div className="quantity">
                                        <div className="form-fields">
                                            <SelectBox
                                                disabled={!this.state.item.showavailabilty}
                                                label="Room"
                                                id="room"
                                                showSearch={false}
                                                placeholder={"Choose"}
                                                value={this.state.room}
                                                options={this.props.cartRooms.map(room => ({ label: room, value: room })).concat([{ label: 'Add New Room', value: 'Neeeew' }])}
                                                onChangeText={this.onChangeText}
                                                optional="true"
                                            />
                                        </div>
                                    </div>
                                    {
                                        this.state.room === 'Neeeew' &&
                                        <div className="select-room">
                                            <div className="form-fields">
                                            <InputBox
                                                label="New Room name"
                                                id="newroom"
                                                value={this.state.newroom}
                                                onChangeText={this.onChangeText}
                                            />
                                            </div>
                                        </div>
                                    }
                                    <div className="action-button margin-top20">
                                        {/* <Button type="secondary">Add to wishlist</Button>
                                        <Button type="primary" onClick={() => this.props.dispatch(addItemToCart({ "matCode": this.state.item.maT_CODE, quantity: 1}))}>Add to cart</Button> */}
                                        <Button disabled={!this.props.context} type="secondary"onClick={this.addToWishList}>Add to wishlist</Button>
                                        <Button disabled={!this.state.room || !this.state.item.showavailabilty} type="primary" onClick={this.addToCart}>Add to cart</Button>
                                        { this.props.alternateProducts && this.props.alternateProducts.length > 0 && <Button disabled={!this.state.room || !this.state.item.showavailabilty} type="primary" onClick={this.addWithBedSetup}>Add With Bed Setup</Button> }
                                    </div>
                                </div>
                            </Col>
                            {/* <Col xs={{ span: 24, order: 3 }} sm={{ span: 12, order: 3 }} md={{ span: 12, order: 4 }} lg={{ span: 12, order: 4 }}>
                                <GetRefund />
                                <GetRefund />
                            </Col> */}

                            {/* <Col xs={{ span: 24, order: 4 }} sm={{ span: 12, order: 4 }} md={{ span: 12, order: 3 }} lg={{ span: 12, order: 3 }}>
                                <RecommendedProducts />
                                <RecommendedProducts />
                                <RecommendedProducts />
                            </Col> */}

                            {/* <Col xs={{ span: 24, order: 5 }} sm={{ span: 24, order: 5 }} md={{ span: 24, order: 5 }} lg={{ span: 24, order: 5 }}>
                                 <div className="title margin-top40">
                                    <h4>Similar products</h4>
                                    <p>Save money and time and also save on shipping expenses by ordering the bundles.</p>
                                </div>
                                <SimilarProducts />
                            </Col> */}

                        </Row>
                    </div>
                </section>


            </React.Fragment >
        )
    }
}
function mapStateToProps(state) {
    return {
        bundlesList: state.bundles.bundlesList,
        userLocation: state.users.userLocation,
        cartRooms: state.cart.cartRooms,
        context: state.login.context,
        alternateProducts: state.furniture.alternateProducts
    }
}
export default connect(mapStateToProps)(withRouter(BundlesDetailedPage))