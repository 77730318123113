import React from 'react';
import { withRouter } from 'react-router';
import {  Button, Popconfirm } from 'antd';
import './PersonalDetails.scss';

class Cards extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="saved-address">
                    <div className="address-fields">
                        <div className="list">
                            <div className="label">Card Number :</div>
                            <div className="content">{this.props.card.cardNo}</div>
                        </div>
                        <div className="list">
                            <div className="label">Card Type :</div>
                            <div className="content">{this.props.card.cardType}</div>
                        </div>
                        <div className="list">
                            <div className="label">Valid Through :</div>
                            <div className="content">{this.props.card.expDate}</div>
                        </div>
                    </div>
                    <div className="edit-delete-action">
                        <Popconfirm title={"Are you sure you want to delete this card?"} okText="Yes Delete" cancelText="Cancel" onConfirm={() => this.props.handleDelete(this.props.card.id) }>
                            <Button type="link"><i className="flaticon-delete-1" /></Button>
                        </Popconfirm>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(Cards)