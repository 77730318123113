import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import { getBundleGroups, getBundleListing } from '../../Actions/BundleActions';

class Bundles extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
        String.prototype.initCap = function () {
            return this.toLowerCase().replace(/(?:^|\b)[a-z]/g, function (m) {
               return m.toUpperCase();
            });
        };
    }
    componentDidMount() {
        if (this.props.bundlesGroups.length === 0) {
            this.setState({ loading: true });
            const bundlesCall = this.props.dispatch(getBundleListing());
            this.props.dispatch(getBundleGroups()).then(() => {
                this.setState({ loading: false });
            })
        }
    }

    setProductType = (id, name) => {
        this.props.history.push(`/bundles-listing/${id}/${name}`)
    };

    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <section className="page-banners bundles">
                    <div className="container mx-auto">
                        <h1>Bundles</h1>
                        <p>Whether you're looking for bespoke luxury furniture in an Art Deco style or a beautifully handcrafted modern sofa, with our tailored selection of designer furniture from the world’s leading designer brands, we have a style to suit every home interior.</p>
                    </div>
                </section>
                {/* page banners */}

                <section className="inner-pages top-right-wave">
                    <div className="container mx-auto">
                        <div className="choose-room-type">
                            <h2>Choose by Room Type</h2>
                            <Row gutter={[12, 20]}>
                                {
                                    this.state.loading && <Spin tip="Please wait while we load the bundle types." />
                                }
                                {
                                    this.props.bundlesGroups.map(bundle => {
                                        return <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Link>
                                                <div className="room-type" onClick={() => this.setProductType(bundle.matgrouP_CODE, bundle.description)}>
                                                    <div className="image">
                                                        <img src={bundle.imagePath} alt="Living Room" />
                                                    </div>
                                                    <div className="details">
                                                        <div className="title">{bundle.description.initCap()}</div>
                                                        <div className="total-bundles">{this.props.bundlesList ? this.props.bundlesList.filter(bundleItem => bundleItem.matgrouP_CODE === bundle.matgrouP_CODE).length : 0} Bundles</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                    })
                                } 
                            </Row>
                        </div>
                        
                    </div>
                </section>


            </React.Fragment >
        )
    }
}
function mapStateToProps(state) {
    return {
        bundlesGroups: state.bundles.bundlesGroups,
        bundlesList: state.bundles.bundlesList
    }
}
export default connect(mapStateToProps)(withRouter(Bundles))