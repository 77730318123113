import React from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

class SideNavigation extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="cart-side-navigation">
                {
                    this.props.context ? 
                    <ul>
                        <li><NavLink to="/my-cart" exact activeClassName="active">My Cart</NavLink></li>
                        <li><NavLink to="/my-wishlist" exact activeClassName="active">My Wishlist</NavLink></li>
                        <li><NavLink to="/recently-viewed" exact activeClassName="active">Recently Viewed</NavLink></li>
                        <li><NavLink to="/open-orders" exact activeClassName="active">Draft Orders</NavLink></li>
                    </ul>
                     :
                     <ul>
                        <li><NavLink to="/my-cart" exact activeClassName="active">My Cart</NavLink></li>
                    </ul>
                }
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(SideNavigation)