import React from 'react';
import { withRouter } from 'react-router';
import './DesignerServices.scss';
import { Row, Col, Button } from 'antd';

class DesignerServices extends React.Component {

    render() {
        return (
            <React.Fragment>
                {/* DesignerServices starts */}
                <section className="designer-services-banner">
                    <div className="banner-caption">
                        <div className="content">
                            <h1>Designer Services</h1>
                            {/* <p>We’ve got a better way, just for Charlotte Home Staging Professionals</p> */}
                            <strong>Designers! Let us help support you with our technology driven Receiving & Delivery services! If you are new customer, please read below and click to create new user.</strong>
                            <a href="https://receiving.stagingdepot.com" target="_blank"><Button type="primary">Go To Portal</Button></a>
                        </div>
                    </div>
                </section>
                {/* DesignerServices ends */}

                {/* our mission */}
                <section className="our-mission">
                    <div className="container mx-auto">
                        <Row gutter={[22, 20]} align="center">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
                                <div style={{ marginTop: '10%' }}>
                                    <p>Our mission is to provide you with great service at a reasonable price, and make you look great in front of your clients. We                         take the stress out of keeping track of all the vendors & shipments needed for your projects. Whether picking up a piece from                         a local vendor, to calling freight companies to coordinate deliveries, we’ll go the extra mile & help you stay organized. Best of all, an online portal with updates on all deliveries including pictures and documentation keeps the process streamlined.</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <div className="designer-service">
                                    <h3>Designer Services</h3>
                                    <p>We've got a better way for Interior Designers</p>
                                    <h4>We take the stress out of…</h4>
                                    <ul>
                                        <li>Shipment Receiving</li>
                                        <li>Shipment Inspection</li>
                                        <li>Professional Assembly</li>
                                        <li>Secure, Clean Storage</li>
                                        <li>White Glove Delivery</li>
                                        {/* <li>Online pictures & documents with project updates</li> */}
                                        <li>Designer Portal - get real-time updates and be able to view items received, upload documents, and share comments</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                {/* our mission */}

                {/* from-start-to-finish */}
                <section className="from-start-to-finish">
                    <div className="container mx-auto">
                        <div className="section-title">
                            <h2>Now there’s an easier way to take away the “hassle” part of your business – from start to finish</h2>
                        </div>
                        <div className="process-steps">
                            <Row gutter={[12, 20]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <div className="steps-details">
                                        <h4>Shipment Receiving</h4>
                                        <p>At our local Charlotte facility.</p>
                                    </div>
                                </Col>
                                {/* step ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <div className="steps-details">
                                        <h4>Shipment Inspection</h4>
                                        <p>Of every piece that arrives</p>
                                    </div>
                                </Col>
                                {/* step ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <div className="steps-details">
                                        <h4>Professional Assembly</h4>
                                        {/* <p>Lets you focus on your clients.</p> */}
                                        <p>No surprises come installation day</p>
                                    </div>
                                </Col>
                                {/* step ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <div className="steps-details">
                                        <h4>Secure, Clean Storage</h4>
                                        <p>Gives you guaranteed peace of mind.</p>
                                    </div>
                                </Col>
                                {/* step ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <div className="steps-details">
                                        <h4>White Glove Delivery</h4>
                                        <p>Gets your pieces where they need to be.</p>
                                    </div>
                                </Col>
                                {/* step ends */}
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <div className="steps-details">
                                        <h4>Online pictures & documents</h4>
                                        <p>with project updates</p>
                                    </div>
                                </Col>
                                {/* step ends */}
                            </Row>
                        </div>
                    </div>
                </section>
                {/* from-start-to-finish */}

                {/* contact now */}
                <section className="contact-now">
                    <div className="container mx-auto">
                        <h2>Contact Now</h2>
                        <Row gutter={[12, 20]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <p>We’d love to hear from you! Let us know what you think of our services and if we can be of any assistance at 1-855-55DEPOT.</p>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <div className="action-buttons">
                                    <Button type="primary" onClick={() => window.location.href = "https://receiving.stagingdepot.com"}>Create User</Button>
                                    <Button type="primary" onClick={() => this.props.history.push('/ContactUs')}>Email Me</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                {/* contact now ends*/}

            </React.Fragment >
        )
    }
}
export default withRouter(DesignerServices)