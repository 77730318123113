import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Collapse, Divider, Row, Slider, Switch } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { addSubGroupFilter, resetAllFilters, setProductAvailability } from '../../../Actions/FurnitureActions';

const { Panel } = Collapse;

const marks = {
    10: '$ 10',
    500: '$ 500'
};
class FurnitureFilter extends React.Component {

    constructor() {
        super()
        this.state = {
            fromDate: '',
            durations: [],
            defaultActiveKey: [],
            symbolsArr: ["e", "E", "+", "-", "."]
        }
        String.prototype.initCap = function () {
            return this.toLowerCase().replace(/(?:^|\b)[a-z]/g, function (m) {
               return m.toUpperCase();
            });
        };
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }

    getProductFilter = (e, type) => {
        if (type === 'available') {
            this.props.dispatch(setProductAvailability({ data: e.target.checked, type: type }));
        }
        else {
            this.props.dispatch(setProductAvailability({ data: e, type: type }));
        };
    };

    handleQuantityChange = (value, type) => {
        const isInteger = /^[0-9]+$/;
        if (value === '' || isInteger.test(value)) {
            if (value !== null) {
                this.props.dispatch(setProductAvailability({ data: value, type: type }));
            }
        }
        else {
            this.props.dispatch(setProductAvailability({ data: '', type: type }));
        };
    };

    disabledDate = (current) => {
        const minDate = moment().subtract(1, 'days');
        return (minDate.isAfter(current));
    };

    render() {
        const { allFilters } = this.props;
        let groups = this.props.groups;
        if (this.props.groupid && this.props.groups.length) {
            groups = [this.props.groups.find(group => group.matgrouP_CODE === this.props.groupid)];
            groups = groups.concat(this.props.groups.filter(group => group.matgrouP_CODE !== this.props.groupid))
        }
        return (
            <React.Fragment>
                <div className="Filter">
                    <Row className="filter-heading">
                        <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <h1><i className="fa fa-filter" /> Filters</h1>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            <Button type="secondary" onClick={() => this.props.dispatch(resetAllFilters())}>Reset</Button>
                        </Col>
                    </Row>
                    {/* filter heading ends */}

                    <Divider />

                    <div className="filter-sections">
                        <h4>Filter By Availability</h4>
                        <div className="form-fields">
                            <label>
                                <Checkbox style={{ float: 'left', margin: '3px' }} checked={allFilters.productAvailable} onChange={(e) => this.getProductFilter(e, 'available')} />
                                <span>Show Only Available Products</span>                          
                            </label>
                        </div>
                    </div>

                    <Divider dashed />

                    <div className="filter-sections">
                        <h4>Price Range</h4>
                        <div className="price-range">
                            <Slider
                                marks={marks}
                                min={0}
                                max={500}
                                // defaultValue={10}
                                value={allFilters.priceRange}
                                onChange={(e) => this.getProductFilter(e, 'priceRange')}
                            />
                        </div>
                    </div>

                    <Divider dashed />

                    <div className="filter-sections margin-bottom20">
                        <div className="options-type">
                            { this.props.customerInfo && this.props.customerInfo.hasInventory && <div className="list-item">
                                <label>My Inventory <Switch size="small" checked={allFilters.myInventory} onChange={(e) => this.getProductFilter(e, 'myInventory')} /></label>
                                <p>See my items managed by Staging Depot.</p>
                            </div> }
                            <div className="list-item">
                                <label>Depot Inventory <Switch checked={allFilters.depotInventory} onChange={(e) => this.getProductFilter(e, 'depotInventory')} size="small" /></label>
                                <p>See inventory owned by Staging Depot.</p>
                            </div>
                            <div className="list-item">
                                <label>COOP Inventory <Switch checked={allFilters.coopInventory} onChange={(e) => this.getProductFilter(e, 'coopInventory')} size="small" /></label>
                                <p>For greater selection and economy pricing, turn this tab on.  This is inventory from other Stagers, managed by us.</p>
                            </div>
                        </div>
                    </div>
                    <h4>{this.props.type === 'room' ? 'Filter by Room' : 'Filter by Furniture'}</h4>
                    {/* accordian view starts */}
                    <Collapse
                        defaultActiveKey={this.props.groupid ? this.props.groupid : ''}
                        expandIconPosition='right'
                        activeKey={this.props.groupid ? this.props.groupid : ''}
                        onChange={(key) => this.props.handleRefresh(key)}
                        expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                        ghost
                    >
                        {
                            groups.map(group => {
                                return <Panel header={group.description.initCap()} key={group.matgrouP_CODE}>
                                    <div className="filter-sections">
                                        <div className="options-type">
                                            <div className="options-list">
                                                {
                                                    this.props.subGroups.map(sg => (
                                                        group.matgrouP_CODE === sg.matgrouP_CODE ?
                                                            <Checkbox value={sg.matsubgrouP_CODE} checked={allFilters.subGroups.includes(sg.matsubgrouP_CODE)} onChange={() => this.props.dispatch(addSubGroupFilter(sg.matsubgrouP_CODE))}>{sg.description.initCap()}</Checkbox>
                                                            : ''
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            })
                        }
                    </Collapse>

                    {/* accordian view ends */}

                </div>
            </React.Fragment >
        )
    }
}

// export default withRouter(FurnitureFilter)

function mapStateToProps(state) {
    return {
        allFilters: state.furniture,
        customerInfo: state.users.customerInfo
    };
};

export default connect(mapStateToProps)(withRouter(FurnitureFilter));