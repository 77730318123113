import { LoginTypes } from '../Actions/ActionType';

const initialState = {
  socialLoginUrls: [],
  profile: sessionStorage.getItem('itop-profile') ? JSON.parse(sessionStorage.getItem('itop-profile')) : undefined,
  context: sessionStorage.getItem('itop-user-context') ? JSON.parse(sessionStorage.getItem('itop-user-context')) : undefined,
  sessionTimeout: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LoginTypes.SET_SOCIAL_LOGIN_URLS: {
      return {
          ...state,
          socialLoginUrls: action.payload
      };
    }
    case 'SESSION_TIMEOUT': {
        sessionStorage.removeItem('itop-profile');
        sessionStorage.removeItem('itop-user-context');
        return {
          ...state,
          sessionTimeout: true
        }      
    }
    case LoginTypes.SET_USER_CONTEXT: {
      sessionStorage.setItem('itop-profile', JSON.stringify(action.profile));
      sessionStorage.setItem('itop-user-context', JSON.stringify(action.payload));
      return {
        ...state,
        profile: action.profile,
        context: action.payload
      }
    }
    default: {
      return state;
    }
  }
}