import { Alert, Button, Col, Row, Spin } from "antd";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  addInspirationsToCart,
  addItemToCart,
  addItemToTempCart,
} from "../../Actions/CartActions";
import {
  getAlternateProducts,
  getFurnitureInfo,
  GetRecommendMaterialInfo,
} from "../../Actions/FurnitureActions";
import { updateItemsInWishList } from "../../Actions/WishListActions";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import InputBox from "../../Components/InputBox/InputBox";
import SelectBox from "../../Components/SelectBox/SelectBox";
import "./FurnitureDetailedPage.scss";
import FurnitureSlider from "./FurnitureSlider/FurnitureSlider";
import Recommended from "./Recommended/Recommended";

class FurnitureDetailedPage extends React.Component {
  constructor() {
    super();
    this.state = {
      selectRoom: [],
      quantity: 1,
      zipCode: "",
      item: undefined,
      duration: "",
      loading: false,
      room: "",
      newroom: "",
      recommendedProducts: [],
    };
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ loading: true });
      this.props.dispatch(getAlternateProducts(this.props.match.params.id, 1));
      getFurnitureInfo(this.props.match.params.id).then((items) => {
        this.setState({ item: items[0], loading: false });
      });
      let isDatacontext = sessionStorage.getItem("itop-user-context")
        ? true
        : false;
      if (isDatacontext === true) {
        GetRecommendMaterialInfo(this.props.match.params.id).then(
          (recommendedProducts) => {
            this.setState({ recommendedProducts });
          }
        );
      }
    }
  }
  onChangeQuantity = (value, type) => {
    this.props.dispatch(getAlternateProducts(this.state.item.maT_CODE, value));
    this.setState({ [type]: value });
  };
  onChangeText = (value, type) => {
    this.setState({ [type]: value });
  };
  onChangeDuration = (value) => {
    this.setState({ duration: value });
  };

  addToCart = (item) => {
    const model = {
      matCode: item.maT_CODE,
      quantity: parseInt(this.state.quantity),
      room: this.state.room === "Neeeew" ? this.state.newroom : this.state.room,
    };
    if (this.props.context) {
      this.props.dispatch(addItemToCart(model)).then(() => this.goBack());
    } else {
      this.props
        .dispatch(addItemToTempCart(item, model.quantity, model.room))
        .then(() => this.goBack());
    }
  };

  addToWishList = (item) => {
    const model = {
      matCode: item.maT_CODE,
    };

    this.props.dispatch(updateItemsInWishList(model));
  };

  addWithBedSetup = () => {
    const alternateProducts = this.props.alternateProducts;
    const items = alternateProducts.map((row) => {
      return {
        matCode: row.alternateProduct,
        quantity: row.qty,
        room:
          this.state.room === "Neeeew" ? this.state.newroom : this.state.room,
      };
    });
    items.push({
      matCode: this.state.item.maT_CODE,
      quantity: parseInt(this.state.quantity),
      room: this.state.room === "Neeeew" ? this.state.newroom : this.state.room,
    });
    this.props
      .dispatch(addInspirationsToCart({ inspirationProducts: items }))
      .then(() => {
        this.goBack();
      });
  };

  goBack = () => {
    this.props.history.push(
      `/furniture-listing/room/${this.state.item.matgrouP_CODE}/${this.state.item.matgrouP_DESC}`
    );
  };

  render() {
    let item = this.state.item;
    if (!item) {
      item = this.props.offerFurnitureList.find(
        (item) => item.maT_CODE === this.props.match.params.id
      );
    }
    if (this.state.loading || !item) {
      return <Spin tip={"Product details are loading. Please wait.."} />;
    }
    const quantityOptions = [];
    for (let i = 1; i <= item.qty; i++) {
      quantityOptions.push({ label: i, value: i });
    }
    return (
      <React.Fragment>
        {/* page banners */}
        <section className="page-banners furniture">
          <div className="container mx-auto">
            <h1>Furniture</h1>
            <p>
              Our Furniture Includes Luxurious Chairs, Sturdy Desks, Chic
              Wardrobes, And Comfortable Beds.
            </p>
            <p>
              You Will Find Pre-Selected, Tasteful Furniture For Your Kitchen,
              Living Room, Dining Room, And Bedroom.
            </p>
          </div>
        </section>
        {/* page banners */}

        <section className="inner-pages top-right-bottom-wave light-bg">
          <div className="container mx-auto">
            <BreadCrumbs
              {...this.props}
              navigations={[
                { name: "Home", link: "/" },
                { name: "furniture", link: "/furniture" },
                {
                  name: item.matgrouP_DESC,
                  link: `/furniture-listing/room/${item.matgrouP_CODE}/${item.matgrouP_DESC}`,
                },
                { name: item.maT_DESC, link: "/furniture-detailed-page" },
              ]}
            />
            {this.props.alternateProducts &&
              this.props.alternateProducts.length > 0 && (
                <Alert
                  style={{ marginTop: "5%" }}
                  description="The bed setup is optional. You can bring your own boxspring/mattress or rent one of ours."
                  type="warning"
                  closable
                />
              )}
            <Row
              gutter={[
                { xs: 8, sm: 20, md: 30, lg: 40 },
                { xs: 8, sm: 16, md: 10, lg: 10 },
              ]}
            >
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 12, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
              >
                <FurnitureSlider image={item.imagePath} batch={item.batch} />
              </Col>
              <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 12, order: 2 }}
                md={{ span: 12, order: 2 }}
                lg={{ span: 12, order: 2 }}
              >
                <div className="detailed-view">
                  <div className="title">
                    <h2>{item.maT_DESC}</h2>
                  </div>
                  <div className="price">$ {item.monthlyrent}/m</div>
                  <div className="furniture-select-fields">
                    {/* <div className="color">
                                            <div className="label">Colors</div>
                                            <div className="content">
                                                {
                                                    item.remark3.split(",").map(color => <span className="colors" style={{background: color }} />)
                                                }
                                            </div>
                                        </div> */}
                    {/* <div className="seating">
                                            <div className="label">Seating</div>
                                            <div className="action-button">
                                                <Button type="secondary">3 Seater</Button>
                                                <Button type="secondary">2 Seater</Button>
                                                <Button type="secondary">1 Seater</Button>
                                            </div>
                                        </div> */}
                    <div className="quantity">
                      <div className="form-fields">
                        <SelectBox
                          disabled={!item.showavailabilty}
                          label="Quantity"
                          id="quantity"
                          placeholder={"Choose"}
                          value={this.state.quantity ? this.state.quantity : 1}
                          options={quantityOptions}
                          onChangeText={this.onChangeQuantity}
                          optional="true"
                        />
                      </div>
                    </div>
                    <div className="quantity">
                      <div className="form-fields">
                        <SelectBox
                          disabled={!item.showavailabilty}
                          label="Room"
                          id="room"
                          showSearch={false}
                          placeholder={"Choose"}
                          value={this.state.room}
                          options={this.props.cartRooms
                            .map((room) => ({ label: room, value: room }))
                            .concat([
                              { label: "Add New Room", value: "Neeeew" },
                            ])}
                          onChangeText={this.onChangeText}
                          optional="true"
                        />
                      </div>
                    </div>
                    {this.state.room === "Neeeew" && (
                      <div className="select-room">
                        <div className="form-fields">
                          <InputBox
                            label="New Room name"
                            id="newroom"
                            value={this.state.newroom}
                            onChangeText={this.onChangeText}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="action-button margin-top20">
                    {/* <Button type="secondary" >Add to wishlist</Button>
                                        <Button type="primary" onClick={() => this.props.dispatch(addItemToCart({ "matCode": this.state.item.maT_CODE, quantity: parseInt(this.state.quantity)})) }>Add to cart</Button> */}
                    <Button
                      disabled={!this.props.context}
                      type="secondary"
                      onClick={() => this.addToWishList(item)}
                    >
                      Add to wishlist
                    </Button>
                    <Button
                    
                      type="primary"
                      onClick={() => this.addToCart(item)}
                    >
                      Add to cart
                    </Button>
                    {item.alternateproduct && (
                      <Button
                        disabled={
                          !this.state.quantity ||
                          !this.state.room ||
                          !item.showavailabilty
                        }
                        type="primary"
                        onClick={this.addWithBedSetup}
                      >
                        Add With Bed Setup
                      </Button>
                    )}
                  </div>

                  {/* products specs starts */}
                  <div className="prouct-details margin-top20">
                    <h2>Product Details</h2>
                    <div className="list">
                      <span className="title">Product</span>
                      <span className="option">
                        {item.showavailabilty ? (
                          "Available"
                        ) : (
                          <span style={{ color: "red" }}>Not Available</span>
                        )}
                      </span>
                    </div>
                    <div className="list">
                      <span className="title">Group</span>
                      <span className="option">{item.matgrouP_DESC}</span>
                    </div>
                    <div className="list">
                      <span className="title">Sub-group</span>
                      <span className="option">{item.matsubgroup}</span>
                    </div>
                    <div className="list">
                      <span className="title">Dimensions</span>
                      <span className="option">{item.remark2}</span>
                    </div>
                    <div className="list">
                      <span className="title">Color</span>
                      <span className="option">{item.remark3}</span>
                    </div>                    
                    <div className="list">
                      <span className="title">Characteristics</span>
                      <span className="option">--</span>
                    </div>
                    <div className="list">
                      <span className="title">Style</span>
                      <span className="option">{item.remark4}</span>
                    </div>
                  </div>
                  {/* products specs ends here */}
                </div>
              </Col>

              {/* <Col className="border-top" xs={{ span: 24, order: 5 }} sm={{ span: 24, order: 5 }} md={{ span: 24, order: 5 }} lg={{ span: 24, order: 5 }}>
                                <div className="title margin-top40">
                                    <h3>Create your own bundle</h3>
                                    <p>Save money and time and also save on shipping expenses by ordering the bundles.</p>
                                </div>
                                <OwnBundle />
                            </Col> */}

              <Col
                xs={{ span: 24, order: 5 }}
                sm={{ span: 24, order: 5 }}
                md={{ span: 24, order: 5 }}
                lg={{ span: 24, order: 5 }}
              >
                <div className="title margin-top20">
                  <h3>Recommended for you</h3>
                  <p>
                    Here is a list of products ordered by other customers who
                    selected this item.
                  </p>
                </div>
                {this.state.recommendedProducts &&
                this.state.recommendedProducts.length > 0 ? (
                  <Recommended items={this.state.recommendedProducts} />
                ) : (
                  <span>No recommended items for now</span>
                )}
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    furnitureList: state.furniture.furnitureList,
    offerFurnitureList: state.furniture.offerFurnitureList,
    userLocation: state.users.userLocation,
    cartRooms: state.cart.cartRooms,
    context: state.login.context,
    alternateProducts: state.furniture.alternateProducts,
  };
}
export default connect(mapStateToProps)(withRouter(FurnitureDetailedPage));
