import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col } from 'antd';
import QuickLinks from '../QuickLinks/QuickLinks';

class Career extends React.Component {

    render() {
        return (
            <React.Fragment>
                {/* page banners */}
                <section className="about-banners">
                    <img src={require('../../assets/images/page-banners/banner.png')} alt="About Us" />
                </section>
                {/* page banners */}

                <div className="inner-pages padding-40 top-left-wave light-bg">
                    <div className="container mx-auto">

                        <QuickLinks />

                        <Row gutter={[{ xs: 8, sm: 20, md: 30, lg: 40 }, { xs: 8, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 16 }} lg={{ span: 16 }}>
                                <h3>Career</h3>
                                <p>We are always in need of reliable, hard working people that are good natured and work well as a team. It is important that  we represent our clients well when interacting with their homeowner cilents. In return, Staging Depot is proud to offer good pay, health Insurance and retirement plan benefits. Future opportunities for growth will abound for self-starters as we look to expand. Anyone with furniture repair skills, and ability to learn/operate furniture management software are a big plus.</p>
                                <p>We have an immediate need for experienced movers with a good driving record and some warehouse experience. Much of the days will be spent driving to delivery furniture with a partner, but to keep things interesting during any down time we also are constantly receiving & assembling furniture, breaking down boxes, and adding items to the warehouse inventory.</p>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <img src={require('../../assets/images/about-us.webp')} alt="About Us" />
                            </Col>
                        </Row>
                    </div>
                </div>


            </React.Fragment >
        )
    }
}
export default withRouter(Career)