import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, Checkbox, Divider } from 'antd';
import InputBox from '../../../Components/InputBox/InputBox';
import getNextNDates from './DatesHelper';
import { selectAddressFromCart, getDeliveryDates, deleteAddress  } from '../../../Actions/OrderActions';
import SelectBox from '../../../Components/SelectBox/SelectBox';
import DateBox from '../../../Components/DateBox/DateBox';
import { getZipCodeDetails, loadCartItems } from '../../../Actions/CartActions';

class Delivery extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addNew: props.selectedAddress ? false : true,
            timings: {
                'AM(09:00-01:00)': 'AM (9-1)',
                'PM(01:00-05:00)': 'PM (1-5)'
            },
            dates: [],
            selectedAddress: undefined,
            address: {
                name: props.customerDetails ? props.customerDetails.name1 :'',
                contact: props.customerDetails ? props.customerDetails.mobile :'',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zipCode: '',
                comments: ''
            },
            deliveryDate: '',
            preferredTiming: "",
            saveAddress: false,
            selectedZipcode: ''
         }
    }

    componentDidMount() {
        this.checkForDates();
    }

    componentWillReceiveProps(newProps) {
        this.checkForDates();
        if (newProps.customerDetails) {
            this.setState({ address: { ...this.state.address, name: newProps.customerDetails.name1, contact: newProps.customerDetails.mobile }})
        }
    }

    onChangeText = (value, type) => {
        this.setState({ selectedAddress: undefined, address: { ...this.state.address, [type]: value } });
    }
    onZipCodeChange = (value, type) => {
        if (value) {
            getZipCodeDetails(value).then(details => {
                this.setState({ address: { ...this.state.address, [type]: value, state: details.stateCode, city: details.city } })
            })
            this.setState({ selectedAddress: undefined });
        }
    }
    selectAddress = (id) => {
        this.setState({ selectedAddress: id, saveAddress: false });
    }
    saveAddress = () => {
        this.props.handleSaveAddress(this.state.address).then(id => {
            this.getDatesBasedOnZipCode(this.state.address.zipCode);
            this.setState({ selectedAddress: id, saveAddress: false, addNew: false });
        })
    }
    save = () => {
        const selectedDate = this.state.dates.find(({ date, timings }) => date === this.state.deliveryDate)
        let selectedTime = selectedDate ? selectedDate.timings.find(time => time.id === this.state.preferredTiming) : { slottime: '' };
        if(this.state.address.comments)
        this.props.saveDeliveryInstructions(this.state.address.comments);
        this.props.handleSave({
            address: this.state.selectedAddress? this.state.selectedAddress : this.state.address,
            saveAddress: this.state.selectedAddress ? false : this.state.saveAddress,
            deliveryDate: this.getFormattedDate(this.state.deliveryDate),
            preferredTiming: this.state.preferredTiming,
            timing: selectedTime.slottime 
        });
    }

    setSavedAddress = async (address) => {
        this.setState({selectedZipCode : address.pincode});
        this.props.dispatch(selectAddressFromCart(address.partneR_FUNCTION));
        const location = this.props.locations.find(loc => loc.ziP_CODE === address.pincode);
        if (location) {
            sessionStorage.setItem("delivery-zipcode", location.ziP_CODE)
            this.getDatesBasedOnZipCode(location.ziP_CODE);
        }
    }

    getDatesBasedOnZipCode = async (zipCode) => {
        this.props.dispatch(loadCartItems(false, zipCode))
        const dates = await getDeliveryDates(zipCode)
        this.setState({ selectedZipcode: zipCode })
        if (dates) {
            this.setState({
                dates
            })
        }
    }

    getFormattedDate = (date) => {
        const dateArr = date ? date.split('-') : ["1970","01","01"]
        return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`
    }

    checkForDates = () => {
        if (this.props.selectedAddress && this.state.dates.length === 0) {
            if (this.props.savedAddresses && this.props.savedAddresses.length > 0) {
                const address = this.props.savedAddresses.find(add => add.partneR_FUNCTION === this.props.selectedAddress);
                if (address) {
                    this.getDatesBasedOnZipCode(address.pincode)
                }
            }

        }
    }

    deleteAddress = (id) => {
        this.setState({selectedZipCode : undefined });
        this.setState({ dates: [] })
        this.props.dispatch(selectAddressFromCart(''));
        this.props.dispatch(deleteAddress(id))
    }

    render() {
        const zipCodes = this.props.locations.map(loc => loc.ziP_CODE);
        return (
            <React.Fragment>
                <div className="staging-details">
                    <h3>{(this.props.selectedAddress || !this.state.addNew) ? 'Choose Address' : 'Add address' }</h3>
                    {
                        (this.props.selectedAddress || !this.state.addNew) && <Button style={{ float: 'right', marginBottom: '2%' }} className="primary" onClick={() => this.setState({ addNew: true }, () => this.setSavedAddress({}))}>Add New</Button>
                    }
                    { (this.props.selectedAddress || !this.state.addNew) && this.props.savedAddresses && this.props.savedAddresses.length !== 0 && <div className="saved-address-details">
                        <div className="saved-address">
                            {
                                this.props.savedAddresses && this.props.savedAddresses.filter(add => zipCodes.includes(add.pincode)).map(address => 
                                    <div onClick={() => this.setSavedAddress(address)} className="list">
                                        <div style={{ textAlign: 'inherit' }} className={`details ${this.props.selectedAddress == address.partneR_FUNCTION ? 'selected' : ''}`}>
                                            <Button type="link" style={{ float: 'right' }} onClick={() => this.deleteAddress(address.partneR_FUNCTION)}><i className="flaticon-delete-1" /></Button>
                                            <p>{address.address}</p>
                                            <p>{address.finaL_ADDRESS}</p> 
                                            <p>{address.city},&nbsp;{address.regioN_STATE} &nbsp;{address.pincode}</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div> 
                    </div>
                    }
                    {this.state.addNew && !this.props.selectedAddress &&
                    <div className="personal-data">
                        <div className="form-fields">
                            <InputBox
                                label="Contact Name"
                                id="name"
                                value={this.state.address.name}
                                onChangeText={this.onChangeText}
                                type="text"
                            />
                        </div>
                        <div className="form-fields">
                            <InputBox
                                label="Contact Number"
                                id="contact"
                                value={this.state.address.contact}
                                onChangeText={this.onChangeText}
                                type="text"
                            />
                        </div>
                        <div className="form-fields">
                            <InputBox
                                label="Address Line 1"
                                id="addressLine1"
                                value={this.state.address.addressLine1}
                                onChangeText={this.onChangeText}
                                type="text"
                            />
                        </div>
                        <div className="form-fields">
                            <InputBox
                                label="Address Line 2"
                                id="addressLine2"
                                value={this.state.address.addressLine2}
                                onChangeText={this.onChangeText}
                                type="text"
                                optional="true"
                            />
                        </div>
                        <div className="form-fields">
                            <SelectBox
                                label="Zip Code"
                                id="zipCode"
                                placeholder={"Choose"}
                                value={this.state.address.zipCode}
                                options={this.props.locations.map(location => ({ label: `${location.ziP_CODE}, ${location.ziP_DESC}`, value: location.ziP_CODE  }))}
                                onChangeText={this.onZipCodeChange}
                                showSearch={true}
                            />
                        </div>
                        <div className="form-fields">
                            <InputBox
                                label="City"
                                id="city"
                                disabled
                                value={this.state.address.city}
                                onChangeText={this.onChangeText}
                                type="text"
                            />
                        </div>
                        <div className="form-fields">
                            <InputBox
                                label="State"
                                id="state"
                                maxLength={2}
                                disabled
                                value={this.state.address.state}
                                onChangeText={this.onChangeText}
                                type="text"
                            />
                        </div>
                        <div className="action-buttpm text-right">
                            <Button type="secondary" onClick={() => this.setState({ addNew: false })}>Choose Existing</Button>
                            &nbsp;&nbsp;
                            <Button type="primary" disabled={!this.state.address.name || !this.state.address.contact || !this.state.address.addressLine1 || !this.state.address.city || !this.state.address.state || !this.state.address.zipCode } onClick={this.saveAddress}>Save</Button>                            
                        </div>
                    </div> }
                    <Divider />
                    {
                        this.props.selectedAddress && this.state.selectedZipcode && 
                        <div className="select-delivery-details">
                            <h5>Select Delivery Date</h5>
                            <div className="delivery-date-time">
                                {
                                    this.state.dates.map(({ date }) => 
                                    <div className="list" onClick={() => this.setState({ deliveryDate: date })}>
                                        <div className={`date-time ${this.state.deliveryDate === date ? 'selected' : ''}`}>{this.getFormattedDate(date)}</div>
                                    </div>
                                    )
                                }
                            </div>
                        </div> 
                    }
                    {
                        this.props.selectedAddress && this.state.deliveryDate  &&
                        <div className="select-delivery-details">
                            <h5>Select Time</h5>
                            <div className="delivery-date-time">
                                {
                                    this.state.dates.map(({ date, timings }) => 
                                        date === this.state.deliveryDate && timings.map(slot => <div className="list" onClick={() => this.setState({ preferredTiming: slot.id })}>
                                            <div className={`date-time ${this.state.preferredTiming === slot.id ? 'selected' : ''}`}>{slot.slottime}</div>
                                        </div> )
                                    )
                                }
                            </div>
                        </div>
                    }
                    { this.props.selectedAddress && this.state.deliveryDate  && this.state.preferredTiming && <div className="form-fields">
                        <InputBox
                            label="Delivery Instructions"
                            id="comments"
                            value={this.state.address.comments}
                            onChangeText={this.onChangeText}
                            type="text"
                            textArea
                            optional="true"
                        />
                        <br />
                    </div> }
                    <div className="action-buttpm text-right">
                        <Button type="primary" disabled={!(this.state.address && this.state.deliveryDate && this.state.preferredTiming) } onClick={this.save}>Choose Payment Method</Button>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default connect()(withRouter(Delivery))