import { BundleTypes } from '../Actions/ActionType';

const initialState = {
  bundlesGroups: [],
  bundlesSubGroups: [],
  bundlesList: [],
  productAvailable: false,
  priceRange: null,
  subGroups: [],
  availableDate: '',
  duration: '',
  myInventory: localStorage.getItem("filters-myInventory") ? localStorage.getItem("filters-myInventory") === "true" : false,
  depotInventory: localStorage.getItem("filters-depotInventory") ? localStorage.getItem("filters-depotInventory") === "true" : true,
  coopInventory: localStorage.getItem("filters-coopInventory") ? localStorage.getItem("filters-coopInventory") === "true" : true
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case BundleTypes.SET_BUNDLE_GROUPS: {
      return {
        ...state,
        bundlesGroups: action.payload
      }
    }
    case BundleTypes.SET_BUNDLE_SUB_GROUPS: {
      return {
        ...state,
        bundlesSubGroups: action.payload
      }
    }
    case BundleTypes.SET_BUNDLE_LIST: {
      return {
        ...state,
        bundlesList: action.payload
      }
    }
    case BundleTypes.SET_AVAILABLE_TYPE: {
      if (action.payload.type === 'available') {
        return {
          ...state,
          productAvailable: action.payload.data
        }
      }
      else if (action.payload.type === 'priceRange') {
        return {
          ...state,
          priceRange: action.payload.data
        }
      }
      else if (action.payload.type === 'date') {
        return {
          ...state,
          availableDate: action.payload.data
        }
      }
      else if (action.payload.type === 'duration') {
        return {
          ...state,
          duration: action.payload.data
        }
      }
      else if (action.payload.type === 'myInventory') {
        localStorage.setItem("filters-myInventory", action.payload.data);
        return {
          ...state,
          myInventory: action.payload.data
        }
      }
      else if (action.payload.type === 'depotInventory') {
        localStorage.setItem("filters-depotInventory", action.payload.data);
        return {
          ...state,
          depotInventory: action.payload.data
        }
      }
      else if (action.payload.type === 'coopInventory') {
        localStorage.setItem("filters-coopInventory", action.payload.data);
        return {
          ...state,
          coopInventory: action.payload.data
        }
      }
    }

    case BundleTypes.RESET_FILTERS: {
      return {
        ...state,
        priceRange: null,
        productAvailable: false,
        duration: '',
        availableDate: '',
        subGroups: []
      }
    }

    case BundleTypes.ADD_SUB_GROUP: {
      const allId = [...state.subGroups]
      const existId = allId.includes(action.payload)
      if (existId === false) {
        return {
          ...state,
          subGroups: [...allId, action.payload]
        }
      }
      else {
        const index = allId.indexOf(action.payload);
        allId.splice(index, 1);
        return {
          ...state,
          subGroups: [...allId]
        }
      }
    }

    case BundleTypes.RESET_SUBGROUP_FILTERS: {
      return {
        ...state,
        subGroups: []
      }
    }

    default: {
      return state;
    }
  }
}