import { Button, DatePicker, Form, Input, Select, TimePicker, Typography } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-datepicker/dist/react-datepicker.css";
// import "react-datetime/css/react-datetime.css";
import { withRouter } from "react-router";
import { sendMessage } from "../../Actions/UserActions";
import "react-phone-input-2/lib/style.css";
import '../SigninSignup/SigninSignup.scss';

// import TimePicker from 'react-time-picker-input';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      businessName: "",
      mobileNumber: "",
      email: "",
      startDate: new Date(),
      date: "",
      time: "",
      errorMessage: ""
    };
  }
  submitInfo = () => {
    const obj = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      businessName: this.state.businessName,
      phone: this.state.mobileNumber,
      email: this.state.email,
      date: this.state.date,
      time: this.state.time,
    };
    const { isValid, err } = this.validateModel(obj)

    if (!isValid) {
      this.setState({ errorMessage: err });
      return;
    }

    this.props.dispatch(sendMessage(obj)).then(success => {
      if (success) {
        window.location.reload()
        this.setState({ errorMessage: "" });
      } else {
        this.setState({ errorMessage: "" });
      }
    })
  };

  validateModel = (form) => {
    let err = '';

    if (!form.date) {
      err = 'Date is required'
    }
    if (form.phone && form.phone.match(/\d/g).length < 11) {
      err = 'please enter valid phone number with country code'
    }
    if (!form.phone) {
      err = 'Phone Number is required'
    }

    if (!form.lastName) {
      err = 'Last Name is required'
    }

    return { isValid: !err, err }
  }

  onChange = (date, dateString) => {
    this.setState({ date: dateString });
  };
  onChangeTime = (value) => {
    const timeString = moment(value).format("HH:mm");
    this.setState({ time: timeString });
  };
  render() {
    const prefixSelector = (
      <Form.Item name="prefix" noStyle>
        <Select
          style={{
            width: 70,
          }}
        >
          <option value="1">+1</option>
          <option value="91">+91</option>
          {/* <Option value="86">+86</Option>
                <Option value="87">+87</Option> */}
        </Select>
      </Form.Item>
    );

    return (
      <React.Fragment>
        {/* page banners */}
        <section className="page-banners furniture">
          <div className="container mx-auto">
            <h1>Contact us</h1>
          </div>
        </section>
        {/* page banners */}

        <section className="inner-pages top-right-wave">
          <div
            className="container mx-auto"
            style={{ textAlign: "-webkit-center", width: '50%', background: 'white', padding: '2%' }}
          >
            <Form
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="horizontal"
              style={{}}
            >
              <Form.Item label="First Name">
                <Input
                  placeholder="First Name"
                  id="firstName"
                  value={this.state.firstName}
                  onChange={(event) =>
                    this.setState({ firstName: event.target.value })
                  }
                  type="text"
                />
              </Form.Item>
              <Form.Item label={<p>Last Name <span style={{ color :'red', fontSize : '20px'}}>*</span></p>}>
                <Input
                  placeholder="Last Name"
                  id="lastName"
                  required={true}
                  value={this.state.lastName}
                  onChange={(event) =>
                    this.setState({ lastName: event.target.value })
                  }
                  type="text"
                />
              </Form.Item>
              <Form.Item label="Business Name">
                <Input
                  placeholder="Business Name"
                  id="businessName"
                  value={this.state.businessName}
                  onChange={(event) =>
                    this.setState({ businessName: event.target.value })
                  }
                  type="text"
                />
              </Form.Item>
              <Form.Item name="phone" label={<p>Phone Number <span style={{ color :'red', fontSize : '20px'}}>*</span></p>}>
                <div className="form-fields">
                  <PhoneInput
                    country={"us"}
                    style={{ width: "100%" }}
                    required={true}
                    onlyCountries={["us", "in"]}
                    value={this.state.mobileNumber}
                    onChange={(mobileNumber) => this.setState({ mobileNumber })}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name={["user", "email"]}
                label="Email"
                rules={[
                  {
                    type: "email",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  id="email"
                  value={this.state.email}
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                  type="text"
                />
              </Form.Item>
              <Form.Item style={{ marginLeft: "110px" }}>
                <b>What Day & Time is best to contact you?</b>
              </Form.Item>
              <Form.Item label={<p>Date <span style={{ color :'red', fontSize : '20px'}}>*</span></p>}>
                <DatePicker format={"DD/MM/YYYY"} onChange={this.onChange} required={true} />
              </Form.Item>
              <Form.Item name="time-picker" label="Time">
                <TimePicker format={"HH:mm"} onChange={this.onChangeTime} />
              </Form.Item>
              {
                this.state.errorMessage && (
                  <Typography style={{ color: 'red' }} variant="caption">
                    {
                      this.state.errorMessage
                    }
                  </Typography>
                )
              }
              <Form.Item
                wrapperCol={{
                  xs: {
                    span: 24,
                    offset: 0,
                  },
                  sm: {
                    span: 16,
                    offset: 8,
                  },
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.submitInfo}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect()(withRouter(ContactUs));
