import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

class PersonalDetailsNavigation extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="cart-side-navigation">
                    <ul>
                        <li><Link to="/personal-details">Personal Details</Link></li>
                        {/* <li><Link to="/personal-details">Address Details</Link></li> */}
                        <li><Link to="/personal-details">Card Details</Link></li>
                    </ul>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(PersonalDetailsNavigation)