import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button,Typography } from 'antd';
import './PersonalDetails.scss';
import InputBox from '../../Components/InputBox/InputBox';
import PersonalDetailsNavigation from './PersonalDetailsNavigation';
import { connect } from 'react-redux';
import { getCustomerDetails, savePersonaldetails } from '../../Actions/UserActions';
import SelectBox from '../../Components/SelectBox/SelectBox';
import { getZipCodeDetails } from '../../Actions/CartActions';
import { checkUserEmail } from '../../Actions/LoginActions'


class EditPersonalDetails extends React.Component {
    constructor() {
        super()
        this.state = {
            name: '',
            mobileNumber: '',
            emailAddress: '',
            companyName: '',
            website: '',
            companyAddress1: '',
            companyAddress2: '',
            city: '',
            state: '',
            zip: '',
            userType: '',
            changedFields: [],
            verifyEmailError: {},
            validateFields: ""
        }
    }

    onChangeText = (value, type) => {
        this.setState({ [type]: value, changedFields: !this.state.changedFields.includes(type) ? [...this.state.changedFields, type] : this.state.changedFields })

        if (type === 'emailAddress') {
            const obj = {
                "email": value,
            };
            let regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/


            if (value !== "") {
                if (regex.test(value)) {
                    this.props.dispatch(checkUserEmail(obj)).then((data) => {
                        if (data.error) {
                            this.setState({ verifyEmailError: data })
                        } else {
                            this.setState({ verifyEmailError: data })
                        }
                    })
                } else {
                    this.setState({ verifyEmailError: { "error": true, "message": "Enter a valid email" } })
                }
            } else {
                this.setState({ verifyEmailError: {} })
            }

        }
    }
    componentDidMount() {
        this.props.dispatch(getCustomerDetails());
    }
    handleSavePersonalDetails = () => {
        const personalDetails = {
            name: this.state.name ? this.state.name : this.props.customerDetails.name1,
            email: this.state.emailAddress ? this.state.emailAddress : this.props.customerDetails.email,
            mobile: this.props.customerDetails.mobile,
            customer: this.props.customerDetails.customer_No,
            userType: this.state.userType ? this.state.userType : this.props.customerDetails.userType,
            companyName: this.state.companyName ? this.state.companyName : this.props.customerDetails.companyName,
            website: this.state.website ? this.state.website : this.props.customerDetails.website,
            companyAddress1: this.state.companyAddress1 ? this.state.companyAddress1 : this.props.customerDetails.companyAddress1,
            companyAddress2: this.state.companyAddress2 ? this.state.companyAddress2 : this.props.customerDetails.companyAddress2,
            city: this.state.city ? this.state.city : this.props.customerDetails.city,
            state: this.state.state ? this.state.state : this.props.customerDetails.regioN_STATE,
            zipcode: this.state.zip ? this.state.zip : this.props.customerDetails.pincode
        }


        const { isValid, err } = this.validateModel(personalDetails)

        if (!isValid) {
            this.setState({ validateFields: err })
            return;
        }

        this.props.dispatch(savePersonaldetails(personalDetails)).then(status => {
            if (status) {
                this.props.history.push("/personal-details")
            }
        })
    }

    validateModel = (model) => {
        let err = ""

        if (!model.zipcode) {
            err = "Enter zipcode"
        }
        if (!model.state) {
            err = "Enter state"
        }
        if (!model.city) {
            err = "Enter city"
        }
        if (!model.companyAddress1) {
            err = "Enter Address Line 1"
        }
        if (!model.companyName) {
            err = "Enter company name"
        }
        if (!model.name) {
            err = "Enter Name"
        }

        return { isValid: !err, err }
    }




    onZipCodeChange = (value, type) => {
        if (value) {
            getZipCodeDetails(value).then(details => {
                this.setState({ [type]: value, state: details.stateCode, city: details.city })
            })
        }
    }
    render() {
        const disallowEmail = this.state.emailAddress === "" ? false : (this.state.verifyEmailError && this.state.verifyEmailError.error)
        // const compName = this.state.companyName ? this.state.companyName : (this.props.customerDetail && this.props.customerDetails.companyName)
        // const disaloowCompany = compName ? false : true
        const disableEmail = this.props.customerDetails && this.props.customerDetails?.email  
        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <div className="edit-personal-details">
                            <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <PersonalDetailsNavigation />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                    <h2 className="margin-bottom10">Personal Details</h2>
                                    <div className="card margin-bottom20">
                                        <div className="form-fields">
                                            <InputBox
                                                label="Name"
                                                id="name"
                                                value={this.state.changedFields.includes('name') ? this.state.name : (this.props.customerDetails ? this.props.customerDetails.name1 : '')}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                                optional={false}
                                            />
                                        </div>
                                        <div className="form-fields">
                                            <InputBox
                                                label="Mobile Number"
                                                id="mobileNumber"
                                                disabled
                                                value={this.props.customerDetails ? this.props.customerDetails.mobile : ''}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                                optional="true"
                                            />
                                        </div>
                                        <div className="form-fields">
                                            <InputBox
                                                label="Email Address"
                                                id="emailAddress"
                                                value={this.state.changedFields.includes('emailAddress') ? this.state.emailAddress : (this.props.customerDetails ? this.props.customerDetails.email : '')}
                                                onChangeText={this.onChangeText}
                                                disabled={disableEmail ? true : false}
                                                type="text"
                                                optional="true"
                                            />
                                            <div>
                                                <span style={{ color: (this.state.verifyEmailError && this.state.verifyEmailError.error === true) ? "red" : "green" }}>
                                                    {this.state.verifyEmailError && this.state.verifyEmailError.message}</span>
                                            </div>
                                            {/* <div className="action-buttons">
                                                <Button type="link">Verify Email Address</Button>
                                            </div> */}
                                        </div>
                                        <div className="form-fields full-width">
                                            <SelectBox
                                                placeholder="User Type"
                                                id="userType"
                                                disabled={this.state.otpGenerated}
                                                value={this.state.changedFields.includes('userType') ? this.state.userType : (this.props.customerDetails ? this.props.customerDetails.userType : '')}
                                                showSearch={false}
                                                style={{ width: '100%' }}
                                                disabled
                                                options={[
                                                    { value: "C03", label: "Designer" },
                                                    { value: "C01", label: "Home Stager" },
                                                    { value: "C02", label: "Realtor" },
                                                    { value: "C06", label: "Builder" },
                                                    { value: "C05", label: "Others" }
                                                ]}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                            />
                                        </div>
                                        <div className="form-fields">
                                            <InputBox
                                                placeholder="Company Name"
                                                label="Company Name"
                                                id="companyName"
                                                value={this.state.changedFields.includes('companyName') ? this.state.companyName : (this.props.customerDetails ? this.props.customerDetails.companyName : '')}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                                optional={false}

                                            />
                                        </div>
                                        <div className="form-fields">
                                            <InputBox
                                                placeholder="Website"
                                                label="Website"
                                                id="website"
                                                value={this.state.changedFields.includes('website') ? this.state.website : (this.props.customerDetails ? this.props.customerDetails.website : '')}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                                optional="true"

                                            />
                                        </div>
                                        <div className="form-fields">
                                            <InputBox
                                                placeholder="Address Line1"
                                                label="Address"
                                                id="companyAddress1"
                                                value={this.state.changedFields.includes('companyAddress1') ? this.state.companyAddress1 : (this.props.customerDetails ? this.props.customerDetails.companyAddress1 : '')}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                                optional={false}

                                            />
                                        </div>
                                        <div className="form-fields">
                                            <InputBox
                                                placeholder="Address Line2"
                                                id="companyAddress2"
                                                value={this.state.changedFields.includes('companyAddress2') ? this.state.companyAddress2 : (this.props.customerDetails ? this.props.customerDetails.companyAddress2 : '')}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                                optional="true"

                                            />
                                        </div>
                                        <div className="form-fields">
                                            <InputBox
                                                placeholder="City"
                                                id="city"
                                                value={this.state.changedFields.includes('city') ? this.state.city : (this.props.customerDetails ? this.props.customerDetails.city : '')}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                                optional={false}
                                            />
                                        </div>
                                        <div className="form-fields">
                                            <InputBox
                                                placeholder="State Abbreviation"
                                                id="state"
                                                value={this.state.changedFields.includes('state') ? this.state.state : (this.props.customerDetails ? this.props.customerDetails.regioN_STATE : '')}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                                optional={false}
                                                maxLength={2}
                                            />
                                        </div>
                                        <div className="form-fields edit-personal-details">
                                            <InputBox
                                                placeholder="Zip"
                                                id="zip"
                                                value={this.state.changedFields.includes('zip') ? this.state.zip : (this.props.customerDetails ? this.props.customerDetails.pincode : '')}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                                optional={false}
                                                maxLength={10}
                                            />
                                        </div>
                                        <div>
                                            {
                                                this.state.validateFields && (
                                                    <Typography style={{ color : 'red'}} variant="caption">
                                                        {
                                                            this.state.validateFields
                                                        }
                                                    </Typography>
                                                )
                                            }
                                        </div>
                                        <div className="action-buttons padding-top20">
                                            <Button type="secondary" onClick={() => this.props.history.push('/personal-details')}>Cancel</Button>
                                            <Button type="primary" onClick={this.handleSavePersonalDetails} disabled={disallowEmail}>Save Details</Button>
                                        </div>
                                    </div>
                                    {/* personal details ends */}

                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
function mapStateToProps(state) {
    return {
        customerDetails: state.users.customerDetails,
        customerInfo: state.users.customerInfo,
        locations: state.users.locations
    }
}
export default connect(mapStateToProps)(withRouter(EditPersonalDetails))