import React from 'react';
import { withRouter } from 'react-router';
import Slider from "react-slick";

const SimilarInspirationssettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                centerMode: true,
                slidesToShow: 3
            }
        },
        {
            breakpoint: 480,
            settings: {
                centerMode: true,
                slidesToShow: 1
            }
        }
    ]
};

class SimilarInspirations extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="similar-products">
                    <Slider {...SimilarInspirationssettings}>

                        <div className="item">
                            <img src={require('../../../assets/images/inspirations/similar-inspirations/1.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/inspirations/similar-inspirations/2.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/inspirations/similar-inspirations/3.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/inspirations/similar-inspirations/4.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/inspirations/similar-inspirations/5.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/inspirations/similar-inspirations/2.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/inspirations/similar-inspirations/3.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/inspirations/similar-inspirations/4.webp')} alt="" />
                        </div>
                        <div className="item">
                            <img src={require('../../../assets/images/inspirations/similar-inspirations/5.webp')} alt="" />
                        </div>
                        
                        

                    </Slider>
                </div>
            </React.Fragment>

        )
    }
}
export default withRouter(SimilarInspirations)