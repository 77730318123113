export const UserType = {
  SET_LOCATIONS: 'SET_LOCATIONS',
  SET_USER_LOCATION: 'SET_USER_LOCATION',
  ADD_ADDRESS: 'ADD_ADDRESS',
  SET_TESTIMONIALS: "SET_TESTIMONIALS",
  SET_CUSTOMER_INFO: "SET_CUSTOMER_INFO",
  SET_CUSTOMER_DETAILS: "SET_CUSTOMER_DETAILS",
  SET_CUSTOMER_TRADE_INFO: "SET_CUSTOMER_TRADE_INFO"
};
export const UtilsType = {
  SET_NOTIFICATION: 'SET_NOTIFICATION'
};
export const LoginTypes = {
  SET_SOCIAL_LOGIN_URLS: 'SET_SOCIAL_LOGIN_URLS',
  SET_USER_CONTEXT: 'SET_USER_CONTEXT',
  SET_CUSTOMER_LOGIN_URLS: 'SET_CUSTOMER_LOGIN_URLS',
};
export const BundleTypes = {
  RESET_SUBGROUP_FILTERS: 'RESET_SUBGROUP_FILTERS',
  SET_BUNDLE_GROUPS: 'SET_BUNDLE_GROUPS',
  SET_BUNDLE_LIST: 'SET_BUNDLE_LIST',
  SET_BUNDLE_SUB_GROUPS: 'SET_BUNDLE_SUB_GROUPS',
  SET_AVAILABLE_TYPE: 'SET_AVAILABLE_TYPE',
  RESET_FILTERS: 'RESET_FILTERS',
  ADD_SUB_GROUP: 'ADD_SUB_GROUP'
};
export const FurnitureTypes = {
  SET_ALTERNATE_PRODUCTS: 'SET_ALTERNATE_PRODUCTS',
  SET_FURNITURE_GROUPS: 'SET_FURNITURE_GROUPS',
  SET_FURNITURE_LIST: 'SET_FURNITURE_LIST',
  SET_OFFER_FURNITURE_LIST: 'SET_OFFER_FURNITURE_LIST',
  SET_FURNITURE_SUB_GROUPS: 'SET_FURNITURE_SUB_GROUPS',
  SET_AVAILABLE_TYPE: 'SET_AVAILABLE_TYPE',
  RESET_FILTERS: 'RESET_FILTERS',
  ADD_SUB_GROUP: 'ADD_SUB_GROUP',
  CLEAR_SUBGROUPS: 'CLEAR_SUBGROUPS',
  SET_MY_INVENTORY: "SET_MY_INVENTORY",
  SET_FILTERS_BY_USER: "SET_FILTERS_BY_USER"
};
export const OrderTypes = {
  SET_DELIVERY_FEE: 'SET_DELIVERY_FEE',
  SET_ORDERS: 'SET_ORDERS',
  ADD_ORDER: 'ADD_ORDER',
  SET_OPEN_ORDERS: 'SET_OPEN_ORDERS',
  GET_OPEN_ORDERS_LIST: 'GET_OPEN_ORDERS_LIST',
  SET_OPEN_ORDERS_LIST: 'SET_OPEN_ORDERS_LIST',
  SAVE_OPEN_ORDERS: 'SAVE_OPEN_ORDERS',
  GET_DETAILS: 'GET_DETAILS',
  SELECTED_ADDRESS_ID_FROM_CART : 'SELECTED_ADDRESS_ID_FROM_CART',
  DELETE_OPEN_ORDER : 'DELETE_OPEN_ORDER',
  UPDATE_OPEN_ORDER : 'UPDATE_OPEN_ORDER',
  PROMPT_OPEN_ORDERS_SELECTION: 'PROMPT_OPEN_ORDERS_SELECTION'
}

export const CardTypes = {
  LOAD_CARDS : 'LOAD_CARDS'
}

export const CartTypes = {
  GET_CART_ITEMS_LOADER : 'GET_CART_ITEMS_LOADER',
  ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
  REMOVE_ITEM_FROM_CART: 'REMOVE_ITEM_FROM_CART',
  SET_CART_ITEMS: 'SET_CART_ITEMS',
  UPDATE_ITEMS_IN_CART: 'UPDATE_ITEMS_IN_CART',
  ADD_ROOM: 'ADD_ROOM',
  SET_ROOMS: 'SET_ROOMS',
  PLACING_ORDER: 'PLACING_ORDER'
}
export const WishListTypes = {
  SET_WISHLIST_ITEMS: 'SET_WISHLIST_ITEMS',
  REMOVE_ITEM_FROM_WISHLIST: 'REMOVE_ITEM_FROM_WISHLIST',
  UPDATE_ITEMS_IN_WISHLIST: 'UPDATE_ITEMS_IN_WISHLIST',
  SET_RECENT_VIEWED_ITEMS: 'SET_RECENT_VIEWED_ITEMS',
  UPDATE_RECENT_VIEWED_ITEMS: 'UPDATE_RECENT_VIEWED_ITEMS',
  SET_POPULAR_ITEMS: 'SET_POPULAR_ITEMS',
  SET_INSPIRATIONS_ITEMS: 'SET_INSPIRATIONS_ITEMS',
  GET_IMAGE_DETAILS: 'GET_IMAGE_DETAILS',
  SET_INSPIRATIONS_LIST: 'SET_INSPIRATIONS_LIST',
  SET_POSITION: 'SET_POSITION'
}
export const AddressTypes = {
  SET_ADDRESS: 'SET_ADDRESS',
  ADD_ADDRESS: 'ADD_ADDRESS',
  DELETE_ADDRESS:'DELETE_ADDRESS',
  EDIT_ADDRESS:'EDIT_ADDRESS'
}
export const PaymentTypes = {
  ADD_CARD_DETAILS : 'ADD_CARD_DETAILS'
}



