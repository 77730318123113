import { CartTypes } from '../Actions/ActionType';

const initialState = {
  cartItems: [],
  cartItemsLoaded: false,
  cartRooms: [],
  cartCostDetails: undefined,
  placingOrder: false
};


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CartTypes.PLACING_ORDER: {
      return {
        ...state,
        placingOrder: action.payload
      }
    }
    
    case CartTypes.SET_CART_ITEMS: {
        let rooms = action.payload ? action.payload.map(item => item.room ? item.room: null) : [];
        const cartRooms = [];
        rooms.forEach(room => {
          if(room && !cartRooms.includes(room)) {
            cartRooms.push(room);
          }
        })
        return {
          ...state,
          cartItems: action.payload,
          cartCostDetails: action.cartCost,
          cartItemsLoaded : false,
          cartRooms
        }
    }

    case CartTypes.GET_CART_ITEMS_LOADER: {
      return {
        ...state,
        cartItemsLoaded : true
      }
    }

    case CartTypes.SET_ROOMS: {
      return {
        ...state,
        cartRooms : action.rooms
      }
    }

    case CartTypes.ADD_ROOM: {
      return {
        ...state,
        cartRooms : state.cartRooms.includes(action.roomName) ? state.cartRooms : [...state.cartRooms, action.roomName ]
      }
    }
    
    case CartTypes.REMOVE_ITEM_FROM_CART: {
        return {
          ...state,
          // cartItems: state.cartItems.filter(({item, quantity}) => item.maT_CODE !== action.itemCode)
          // cartItems: state.cartItems.filter(({itemId}) => itemId !== action.itemCode)
        }
    }
    default: {
      return state;
    }
  }
}