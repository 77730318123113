import { Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { deleteCard, getCards, saveNewCard } from '../../Actions/CardActions';
import { deleteAddress, getCustomerDetails } from '../../Actions/OrderActions';
import CardDetails from './CardDetails';
import './PersonalDetails.scss';
import PersonalDetailsNavigation from './PersonalDetailsNavigation';



class PersonalDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            edit: false,
            userTypes: [
                { "C03": "Designer" },
                { "C01": "Home Stager" },
                { "C02": "Realtor" },
                { "C06": "Builder" },
                { "C05": "Others" }
            ]
        }
    };

    componentDidMount() {
        this.props.dispatch(getCards());
        this.props.dispatch(getCustomerDetails());

    };

    handleDeleteAddress = (AddressID) => {
        this.props.dispatch(deleteAddress(AddressID))
    }

    render() {

        const { customerDetails } = this.props.customerDetails;

        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <div className="personal-details">
                            <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                <Col xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <PersonalDetailsNavigation />
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 18 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                    <h2 className="margin-bottom10">Personal Details</h2>
                                    <div className="personal-details card margin-bottom20">
                                        <Row>
                                            <Col xs={{ span: 24 }} sm={{ span: 19 }} md={{ span: 19 }} lg={{ span: 19 }}>

                                                <div className="list">
                                                    <div className="label">Full Name</div>
                                                    <div className="content">{customerDetails && customerDetails.name1}</div>
                                                </div>
                                                <div className="list">
                                                    <div className="label">Mobile No.</div>
                                                    <div className="content">{customerDetails && customerDetails.mobile}</div>
                                                </div>
                                                <div className="list">
                                                    <div className="label">Email Address</div>
                                                    <div className="content">{customerDetails && customerDetails.email}</div>
                                                </div>
                                                <div className="list">
                                                    <div className="label">User type</div>
                                                    <div className="content">{customerDetails && customerDetails.userType}</div>
                                                </div>
                                                <div className="list">
                                                    <div className="label">Company Name</div>
                                                    <div className="content">{customerDetails && customerDetails.companyName}</div>
                                                </div>
                                                <div className="list">
                                                    <div className="label">Website</div>
                                                    <div className="content">{customerDetails && customerDetails.website}</div>
                                                </div>
                                                <div className="list">
                                                    <div className="label">Address</div>
                                                    <div className="content" style={{ verticalAlign: 'text-top' }}>
                                                        { customerDetails && customerDetails.companyAddress1 && <p>{customerDetails.companyAddress1}</p> }
                                                        { customerDetails && customerDetails.companyAddress2 && <p>{customerDetails.companyAddress2}</p> }
                                                        { customerDetails && <p>{customerDetails.city},&nbsp;{customerDetails.regioN_STATE}&nbsp;{customerDetails.pincode}</p> }
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="text-right" xs={{ span: 24 }} sm={{ span: 5 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                                <Link to="/edit-personal-details"><i className="flaticon-edit" /></Link>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardDetails />
                                    
                                    {/* card details ends */}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
function mapStateToProps(state) {
    return {
        savedAddresses: state.orders.savedAddresses,
        customerDetails: state.orders.customerDetails,
        cards: state.users.cards
    }

}
// export default withRouter(PersonalDetails)
export default connect(mapStateToProps)(withRouter(PersonalDetails));