import { tsImportEqualsDeclaration } from '@babel/types';
import { Button, Divider, Popover, Radio } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { saveOpenOrder } from '../../../Actions/OrderActions';
import InputBox from '../../../Components/InputBox/InputBox';
import ModalBox from '../../../Components/ModalBox/ModalBox';
import SaleAgreement from '../SaleAgreement';
import ExistingOpenOrders from './ExistingOpenOrders';

class OrderDetails extends React.Component {
    constructor() {
        super()
        this.state = {
            tNCAccepted: false,
            showAgreement: false,
            visible: false,
            deliveryVisible: false,
            couponCode: '',
            selectedRadio: "",
            existingOrders: false
        }
    }
    // popover
    hide = () => {
        this.setState({
            visible: false,
        });
    };

    handleRadioChange = e => {
        if (e.target.value === 1) {
            this.setState({ selectedRadio: e.target.value, existingOrders: true })
        } else if (e.target.value === 2) {
            this.setState({ selectedRadio: e.target.value, existingOrders: false })
        }

    };

    handleVisibleChange = visible => {
        this.setState({ visible });
    };

    handleDeliveryVisibleChange = deliveryVisible => {
        this.setState({ deliveryVisible });
    };

    getDeliveryFees = () => {
        let itemCount = 0;
        this.props.cartItems.forEach(({ quantity }) => {
            itemCount = itemCount + quantity;
        })
        let deliveryFee = 0;
        this.props.deliveryFee.forEach(item => {
            if (itemCount >= item.fromQty && itemCount <= item.toQty) {
                deliveryFee = item.amount;
            }
        });
        return deliveryFee;
    }

    renderDeliveryFeeDetails = () => {
        return <div>
            <h2>Delivery, Installation and return pickup charges.</h2>
            <Divider />
            {
                this.props.deliveryFee.map(item => {
                    return <div><label style={{ width: '50%' }}>From {item.fromQty} items to {item.toQty} items </label>- ${item.amount}</div>
                })
            }
            <Divider />
        </div>
    }
    onChangeCoupanCode = (value) => {
        this.setState({ couponCode: value });
    }
    getMonthlyRental = () => {
        let monthlyRental = 0;
        this.props.cartItems.forEach(({ price, quantity }) => {
            monthlyRental = monthlyRental + parseInt(price) * quantity;
        })
        return monthlyRental;
    }
    verifyUser = (userDetails) => {
        this.props.verifyUser(userDetails);
    }
    placeOrder = () => {
        const { couponCode } = this.state;
        this.props.placeOrder({ couponCode });
    }

    handleSaveOpenOrder = () => {
        let items = []
        this.props.cartItems.forEach((item, index) => {
            items = [...items, { "matcode": item.matCode, "room": item.room, "qty": item.quantity }]
        })
        let model = {
            "addressType": (this.props.addressId && this.props.addressId.id) || Number(this.props.selectedAddressId)
        }
        this.props.dispatch(saveOpenOrder(model)).then((success) => {
            if (success)
                this.props.history.push(`/open-orders`)
        })
    }

    onCancel = () => {
        this.setState({ existingOrders: false, showAgreement: false, selectedRadio: "" });
    }

    render() {
        const monthlyRental = this.getMonthlyRental();
        const disAllowSave = (Object.entries(this.props.addressId).length === 0 && this.props.selectedAddressId === "") ? true : false
        const deliveryFee = this.getDeliveryFees();
        const monthlyRent = this.props.costDetails && this.props.costDetails.find(costItem => costItem.description === "Monthly Rent");
        const discountPrice = this.props.costDetails && this.props.costDetails.find(costItem => costItem.description === "Trade Discount");
        let ownerDiscount = this.props.costDetails && this.props.costDetails.find(costItem => costItem.description === "Owner Deduction");
        const stateTax = this.props.costDetails && this.props.costDetails.find(costItem => costItem.description === "State and County Tax");
        const disAllowQty = this.props.cartItems && this.props.cartItems.filter(v => v.quantity === 0)
        ownerDiscount = ownerDiscount ? ownerDiscount.amount : 0;
        //const discountPrice = '';
        
        return (
            <React.Fragment>
                <div className="order-pricing-details">
                    {this.props.costDetails && <div className="card-view">
                        <div className="title">
                            <h3>Cost Details</h3>
                        </div>
                        <div className="rental-details">
                            {
                                this.props.costDetails.map(costItem => costItem.description === "Monthly Rent" && <div className="monthly-rental">{costItem.description} <span style={{ fontFamily: "sans-serif", fontSize: '16px' }}>$ {parseFloat(costItem.amount).toFixed(2)}</span></div>)
                            }
                            {
                                this.props.costDetails.map(costItem => costItem.description === "Trade Discount" && <div className="monthly-rental">{costItem.description} <span style={{ fontFamily: "sans-serif", fontSize: '16px' }}>$ {parseFloat(costItem.amount).toFixed(2)}</span></div>)
                            }
                            {
                                discountPrice && <div className="monthly-rental">Monthly after discount<span style={{ fontFamily: "sans-serif", fontSize: '16px' }}>$ {(parseFloat(monthlyRent.amount) - parseFloat(discountPrice.amount)).toFixed(2)}</span></div>
                            }
                            {
                                this.props.costDetails.map(costItem => costItem.description !== "Trade Discount" && costItem.isTotal === "0" && costItem.description !== "Monthly Rent" && <div className="monthly-rental">{costItem.description} <span style={{ fontFamily: "sans-serif", fontSize: '16px' }}>$ {parseFloat(costItem.amount).toFixed(2)}</span></div>)
                            }
                            {
                                // discountPrice && <div className="monthly-rental">Recurring Monthly Rent<span style={{ fontFamily: "sans-serif", fontSize: '16px' }}>$ {(parseFloat(monthlyRent.amount) - parseFloat(discountPrice.amount) - parseFloat(ownerDiscount)).toFixed(2)}</span></div>
                                discountPrice && <div className="monthly-rental">Recurring Monthly Rent<span style={{ fontFamily: "sans-serif", fontSize: '16px' }}>$ {((parseFloat(monthlyRent.amount) + (stateTax ? parseFloat(stateTax.amount) : 0)) - parseFloat(discountPrice.amount) - (ownerDiscount ? parseFloat(ownerDiscount) : 0)).toFixed(2)}</span></div>
                            }
                        </div>
                        <div className="cart-discount" style={{ paddingTop: '5%' }}>
                            {
                                ((discountPrice || ownerDiscount) ?
                                    <div />
                                    :
                                    (
                                        this.props.customerTradeInfo && this.props.customerTradeInfo.companyName && this.props.customerTradeInfo.website ?
                                            <div style={{ margin: '2%' }}>
                                                <span><h5><i className="fa fa-info-circle" style={{ marginLeft: "6px" }} />&nbsp;Trade pricing cannot be applied until you've been verified. Please call us at: <br /><a href="tel:+1855-553-3768">1-855-55DEPOT (855-553-3768)</a></h5></span>
                                            </div>
                                            :
                                            <div>
                                                <span><h5><i className="fa fa-info-circle" style={{ marginLeft: "6px" }} />&nbsp;If you are in the trade, please enter your trade details so you can get verified and qualify for trade pricing.</h5><a className='reminder-email' onClick={() => this.props.history.push("/edit-personal-details")}>Edit Trade Details</a></span>
                                            </div>
                                    )
                                )
                            }
                        </div>
                        <div className="sub-total">
                            {
                                this.props.costDetails.map(costItem => costItem.isTotal === "1" && <div className="price">{costItem.description} <span style={{ fontFamily: "sans-serif", fontSize: '16px' }}>$ {parseFloat(costItem.amount).toFixed(2)}</span></div>)
                            }
                        </div>
                        <div className="action-button">
                            {<Button onClick={() => this.setState({ showAgreement: true })} title={!this.props.isValid || this.props.cartItems.length === 0 ? "Please choose the address and delivery date time to place the order." : ""} disabled={!this.props.isValid || this.props.cartItems.length === 0 || disAllowQty.length > 0} type="primary">{(discountPrice || ownerDiscount) ? 'Place Order' : 'Checkout without trade discount'}</Button>}
                        </div>
                    </div>}
                    {/* renting details ends */}
                    {(this.props.cartItems.length > 0 && this.props.loggedUser) && <div className="card-view">
                        <div className="title">
                            {disAllowSave ? <p><i className="fa fa-info-circle" /> Please click on Staging Details and add the staging address to save draft order</p> : <p>Would you like to save your cart as a draft order?</p>}
                        </div>
                        <div className="margin-bottom20">
                            {/* <Checkbox>Save in existing Draft order</Checkbox>
                            <Checkbox>Save as new Draft order</Checkbox> */}
                            <Radio.Group onChange={this.handleRadioChange} value={this.state.selectedRadio}>
                                <Radio value={2}>Save as draft order</Radio>
                                <Radio value={1} disabled={!this.props.cartItems.length}>Add cart items to old draft order</Radio>
                            </Radio.Group>
                        </div>
                        <div className="action-button">
                            <Button type="primary" title={disAllowSave ? "Please choose the address to save as draft order" : ""} onClick={this.handleSaveOpenOrder} disabled={disAllowSave}>Save As Draft Order</Button>
                        </div>
                    </div>}
                    {/* save order ends*/}

                </div>
                <ModalBox
                    visible={this.state.existingOrders}
                    width={854}
                    wrapClassName="main-search-area"
                    onCancel={this.onCancel}
                    footer={null}
                    destroyOnClose>
                    <ExistingOpenOrders handleClose={this.onCancel} cartItems={this.props.cartItems} />
                </ModalBox>
                {/* Quick view modal box */}
                <ModalBox
                    visible={this.state.showAgreement}
                    style={{ top: '20px' }}
                    width={1200}
                    height="700px"
                    wrapClassName="main-search-area"
                    onCancel={this.onCancel}
                    footer={null}
                    destroyOnClose>
                    <SaleAgreement placingOrder={this.props.placingOrder} placeOrder={this.placeOrder} verifyUser={this.verifyUser} deliveryDate={this.props.deliveryDate} selectedAddress={this.props.selectedAddress} monthlyRental={monthlyRental} customerDetails={this.props.customerDetails} customerInfo={this.props.customerInfo} totalAmount={monthlyRental + deliveryFee} handleChange={(checked) => this.setState({ tNCAccepted: checked })} handleClose={this.onCancel} tradeInfo={this.props.customerTradeInfo} />
                </ModalBox>
                {/* Quick view modal box ends */}
            </React.Fragment >
        )
    }
}

function mapStateToProps(state) {
    return {
        addressId: state.users.saveAddressSuccess,
        selectedAddressId: state.orders.selectedAddressId,
        profile: state.login.profile,
        customerDetails: state.users.customerDetails,
        customerInfo: state.users.customerInfo,
        customerTradeInfo: state.users.customerDetails,
        placingOrder: state.cart.placingOrder
    }
}
export default connect(mapStateToProps)(withRouter(OrderDetails))