import axios from "axios";
import AppConfig from "../config";
import {
  AddressTypes,
  CartTypes,
  FurnitureTypes,
  OrderTypes,
  UserType,
} from "./ActionType";
import { getAxios } from "./setAuthorizationToken";

const API_BASE_URL = AppConfig.API_BASE_URL;

export const saveAddress = (address) => {
  return function (dispatch) {
    return getAxios(dispatch)
      .post(`${API_BASE_URL}/api/CustomerPortal/CreateAddress`, address)
      .then((res) => {
        if (res.data && res.data.id) {
          axios
            .get(`${API_BASE_URL}/api/CustomerPortal/GetAddress`)
            .then((response) => {
              if (response.data && response.data.adddressList) {
                dispatch({
                  type: AddressTypes.SET_ADDRESS,
                  payload: response.data.adddressList,
                });
                dispatch({
                  type: OrderTypes.SELECTED_ADDRESS_ID_FROM_CART,
                  payload: res.data.id,
                });
              } else {
                dispatch({ type: AddressTypes.SET_ADDRESS, payload: [] });
              }
            })
            .catch((error) => { });
        } else {
          dispatch({
            type: "SET_NOTIFICATION",
            notification: { type: "ERROR", message: "Saving address failed." },
          });
          return false;
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_NOTIFICATION",
          notification: { type: "ERROR", message: "Saving address failed." },
        });
        return false;
      });
  };
};


export const deleteAddress = (id) => {
  return function (dispatch) {
    return getAxios(dispatch)
      .post(`${API_BASE_URL}/api/CustomerPortal/DeleteAddress?AddressID=${id}`)
      .then((res) => {
        if (res.data) {
          axios
            .get(`${API_BASE_URL}/api/CustomerPortal/GetAddress`)
            .then((response) => {
              if (response.data && response.data.adddressList) {
                dispatch({
                  type: AddressTypes.SET_ADDRESS,
                  payload: response.data.adddressList,
                });
              } else {
                dispatch({ type: AddressTypes.SET_ADDRESS, payload: [] });
              }
            })
            .catch((error) => { });
        } else {
          dispatch({
            type: "SET_NOTIFICATION",
            notification: { type: "ERROR", message: "Delete address failed." },
          });
          return false;
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_NOTIFICATION",
          notification: { type: "ERROR", message: "Delete address failed." },
        });
        return false;
      });
  };
};

export const saveTestimonial = (testimonial) => {
  return function (dispatch) {
    return getAxios(dispatch)
      .post(`${API_BASE_URL}/api/CustomerPortal/SaveTestimonial`, {
        testimonial,
      })
      .then((res) => {
        if (res && res.data && res.data.id) {
          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_NOTIFICATION",
          error: true,
          message: "Saving testimonial failed.",
        });
      });
  };
};

export const getTradeCustomer = () => {
  return function (dispatch) {
    return getAxios(dispatch)
      .get(`${API_BASE_URL}/api/CustomerPortal/GetCustomerTrades`)
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.customerTrade &&
          res.data.customerTrade.length > 0
        ) {
          dispatch({
            type: UserType.SET_CUSTOMER_TRADE_INFO,
            payload: res.data.customerTrade[0],
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_NOTIFICATION",
          error: true,
          message: "Creating trade group user failed.",
        });
      });
  };
};

export const createTradeGroupUser = () => {
  return function (dispatch) {
    const userObj = localStorage.getItem("trade-user")
      ? JSON.parse(localStorage.getItem("trade-user"))
      : undefined;
    if (userObj) {
      return getAxios(dispatch)
        .post(
          `${API_BASE_URL}/api/CustomerPortal/${userObj.companyName ? "CreateCustomerTrade" : "CreateCustomer"
          }`,
          userObj
        )
        .then((res) => {
          if (res && res.data) {
            localStorage.removeItem("trade-user");
            if (res?.data?.status === 0) {
              sessionStorage.removeItem('itop-profile');
              sessionStorage.removeItem('itop-user-context');
              dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: res?.data?.message } })
              setTimeout(() => {
                window.location.href = "/";
              }, 5000)
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: "SET_NOTIFICATION",
            error: true,
            message: "Creating trade group user failed.",
          });
        });
    }
  };
};

export const getCustomerDetails = () => {
  return function (dispatch) {
    return getAxios(dispatch)
      .get(`${API_BASE_URL}/api/CustomerPortal/GetCustomer`)
      .then((res) => {
        if (res.data && res.data.customerDetails) {
          dispatch({
            type: UserType.SET_CUSTOMER_DETAILS,
            payload: res.data.customerDetails,
          });
        } else {
          dispatch({ type: UserType.SET_CUSTOMER_DETAILS, payload: {} });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_NOTIFICATION",
          error: true,
          message: "Getting Customer details failed.",
        });
      });
  };
};

export const getCustomerInfo = () => {
  return function (dispatch) {
    return getAxios(dispatch)
      .get(`${API_BASE_URL}/api/CustomerPortal/GetCustomerInfo`)
      .then((res) => {
        if (res.data && res.data.customerInfo) {
          dispatch({
            type: UserType.SET_CUSTOMER_INFO,
            payload: res.data.customerInfo,
          });
          dispatch({
            type: FurnitureTypes.SET_FILTERS_BY_USER,
            hasInventory: res.data.customerInfo.hasInventory,
          });
        } else {
          dispatch({ type: UserType.SET_CUSTOMER_INFO, payload: {} });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_NOTIFICATION",
          error: true,
          message: "Getting User info failed.",
        });
      });
  };
};

export const getTestimonials = () => {
  return function (dispatch) {
    return getAxios(dispatch)
      .get(`${API_BASE_URL}/api/CustomerPortal/GetTestimonial`)
      .then((res) => {
        if (res.data && res.data.testimonialList) {
          dispatch({
            type: UserType.SET_TESTIMONIALS,
            payload: res.data.testimonialList,
          });
        } else {
          dispatch({ type: UserType.SET_TESTIMONIALS, payload: [] });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_NOTIFICATION",
          error: true,
          message: "Getting Testimonials failed.",
        });
      });
  };
};

export const getLocations = () => {
  return function (dispatch) {
    return getAxios(dispatch)
      .get(`${API_BASE_URL}/api/CustomerPortal/GetLocation`)
      .then((res) => {
        if (res.data && res.data.locationList) {
          dispatch({
            type: UserType.SET_LOCATIONS,
            payload: res.data.locationList,
          });
        } else {
          dispatch({ type: UserType.SET_LOCATIONS, payload: [] });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_NOTIFICATION",
          error: true,
          message: "Getting Location Details failed.",
        });
      });
  };
};

export function changeLocation(locationObject) {
  return function (dispatch) {
    return dispatch({
      type: UserType.SET_USER_LOCATION,
      payload: locationObject,
    });
  };
}
export const sendMessage = (obj) => {
  return function (dispatch) {
    return getAxios(dispatch)
      .post(`${API_BASE_URL}/api/CustomerPortal/CreateContactus`, obj)
      .then((res) => {
        if (res && res.data && res.data.id) {
          dispatch({
            type: "SET_NOTIFICATION",
            error: false,
            message: "Message sent successfully.",
          });
          return true;
        } else {
          dispatch({
            type: "SET_NOTIFICATION",
            error: true,
            message: "Error in sending message.",
          });
          return false;
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_NOTIFICATION",
          error: true,
          message: "Error in sending message.",
        });
        return false;
      });
  };
};
export const savePersonaldetails = (obj) => {
  return function (dispatch) {
    return getAxios(dispatch)
      .post(`${API_BASE_URL}/api/CustomerPortal/CreateCustomerTrade`, obj)
      .then((res) => {
        if (res && res.data && res.data.id) {
          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_NOTIFICATION",
          error: true,
          message: "Details saved successfully.",
        });
      });
  };
};
