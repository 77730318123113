import React from 'react';
import HowitWorks from '../LandingPage/HowitWorks/HowitWorks';
import PopularProducts from '../LandingPage/PopularProducts/PopularProducts';
import BundlesHome from '../LandingPage/BundlesHome/BundlesHome';
import RentYourFurnitures from '../LandingPage/RentYourFurnitures/RentYourFurnitures';
import Testimonials from '../LandingPage/Testimonials/Testimonials';
import StagingDepotCommitment from '../LandingPage/StagingDepotCommitment/StagingDepotCommitment';


export default class Home extends React.Component {
    render() {
        return (
            <React.Fragment>

                {/* how it works starts*/}
                <HowitWorks />
                {/* how it works ends*/}

                {/* PopularProducts starts */}
                <PopularProducts />
                {/* Popular Products ends */}

                {/* Bundles starts */}
                <BundlesHome />
                {/* Bundles starts */}

                {/* RentYourFurnitures starts */}
                <RentYourFurnitures />
                {/* RentYourFurnitures ends */}

                {/* Testimonials starts */}
                <Testimonials />
                {/* Testimonials ends */}

                {/* rent your furniture starts */}
                <StagingDepotCommitment />


            </React.Fragment>
        );
    }
}