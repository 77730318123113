import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button, Popconfirm, Alert, Space } from 'antd';
import './QuickView.scss';
import { connect } from 'react-redux';
import { addInspirationsToCart, addItemToCart, addItemToTempCart } from '../../../Actions/CartActions';
import SelectBox from '../../../Components/SelectBox/SelectBox';
import InputBox from '../../../Components/InputBox/InputBox';
import FurnitureSlider from '../../FurnitureDetailedPage/FurnitureSlider/FurnitureSlider';
import { getAlternateProducts } from '../../../Actions/FurnitureActions';

class QuickView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            room: '',
            newroom: '',
            quantity: 1,
            headBoardError: false
        }
        String.prototype.initCap = function () {
            return this.toLowerCase().replace(/(?:^|\b)[a-z]/g, function (m) {
               return m.toUpperCase();
            });
        };
    }
    componentDidMount() {
        this.props.dispatch(getAlternateProducts(this.props.product.maT_CODE, 1));
    }
    onQuantityChange = (value, type) => {
        this.props.dispatch(getAlternateProducts(this.props.product.maT_CODE, value));
        this.setState({ [type]: value })
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    nextPath(path) {
        this.props.history.push(path);
    }
    getButtonText = (action, item) => {
        const { product } = this.props;
        return <Button type="primary" onClick={() => this.addItem(this.props.product)}>{item ? "Add again" : "Add to cart"}</Button>
    }

    addItem = (item) => {
        const model = {
            "matCode": this.props.product.maT_CODE,
            "quantity": parseInt(this.state.quantity),
            "room": this.state.room === 'Neeeew' ? this.state.newroom : this.state.room
        }
        if (this.props.context) {
            this.props.dispatch(addItemToCart(model,true))
        } else {
            this.props.dispatch(addItemToTempCart(this.props.product, model.quantity, model.room));
        }
        this.props.handleClose()
    }

    addWithBedSetup = () => {
        const alternateProducts = this.props.alternateProducts;
        const quantity = parseInt(this.state.quantity);
        const room = this.state.room === 'Neeeew' ? this.state.newroom : this.state.room
        const items = alternateProducts.map(row => {
            return {
                matCode: row.alternateProduct,
                quantity: row.qty,
                room
            }
        });
        items.push({
            matCode: this.props.product.maT_CODE,
            quantity,
            room
        })
        this.props.dispatch(addInspirationsToCart({ "inspirationProducts" : items }))
        this.props.handleClose()
    }

    render() {
        const cartItem = this.props.cartItems.find(({ matCode }) => this.props.product.maT_CODE === matCode);
        const quantityOptions = [];
        for(let i=1; i<=this.props.product.qty; i++) {
            quantityOptions.push({ label: i, value: i })
        }
        console.log('-----', this.props.product.alternateproduct)
        console.log('-----',  this.props.alternateProducts)
        return (
            <React.Fragment>
                <div className="quick-view-furniture">
                    { this.props.alternateProducts && this.props.alternateProducts.length > 0 && <Alert
                        style={{ marginTop: '5%' }}
                        description="The bed setup is optional. You can bring your own boxspring/mattress or rent one of ours."
                        type="warning"
                        closable
                    /> }
                    <div className="title">
                        <h2>{this.props.product.maT_DESC}</h2>
                        <p>{this.props.product.remark1}</p>
                    </div>
                    <Row gutter={[20, 10]}>
                        <Col xs={{ span: 24 }} sm={{ span: 15 }} md={{ span: 15 }} lg={{ span: 15 }} style={{ textAlign: 'center' }}>
                            <FurnitureSlider image={this.props.product.imagePath} batch={this.props.product.batch}  />
                            {/* <img src={this.props.product.imagePath} style={{ maxWidth: 500, maxHeight: 350 }} alt="Furnture Overview" /> */}
                            <div className="other-details">
                                <div className="rent">
                                    <span className="title-price">Rent: $ {this.props.product.monthlyrent}/mon</span>
                                </div>
                                <div className="shipping">
                                    { this.props.product.showavailabilty === 0 && 
                                        <span style={{ color: 'red' }}>Out of stock</span>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 9 }} md={{ span: 9 }} lg={{ span: 9 }}>
                            <div className="prouct-details">
                                <div className="list">
                                    <span className="title">Product</span>
                                    <span className="option">{ this.props.product.showavailabilty ? 'Available' : <span style={{ color: 'red' }}>Not Available</span> }</span>
                                </div>
                                <div className="list">
                                    <span className="title">Group</span>
                                    <span className="option">{this.props.product.matgrouP_DESC ? this.props.product.matgrouP_DESC.initCap() : ''}</span>
                                </div>
                                <div className="list">
                                    <span className="title">Sub-group</span>
                                    <span className="option">{this.props.product.matsubgroup ? this.props.product.matsubgroup.initCap() : ''}</span>
                                </div>
                                <div className="list">
                                    <span className="title">Dimensions</span>
                                    <span className="option">{this.props.product.remark2}</span>
                                </div>
                                <div className="list">
                                    <span className="title">Color</span>
                                    <span className="option">{this.props.product.remark3}</span>
                                </div>                                
                                <div className="list">
                                    <span className="title">Characteristics</span>
                                    <span className="option">{this.props.product.remark6}</span>
                                </div>
                                <div className="list">
                                    <span className="title">Style</span>
                                    <span className="option" style={{ verticalAlign: 'top'}}>{this.props.product.remark4}</span>
                                </div>
                                <div className="list">
                                    <SelectBox
                                        disabled={!this.props.product.showavailabilty}
                                        id="quantity"
                                        label="Quantity"
                                        style={{ width: '100%' }}
                                        placeholder={"Choose"}
                                        value={this.state.quantity ? this.state.quantity : 1}
                                        options={quantityOptions}
                                        onChangeText={this.onQuantityChange}
                                        optional="true"
                                    />
                                </div>
                                {
                                    this.state.room === 'Neeeew' &&
                                    <div className="select-room">
                                        <div className="form-fields">
                                        <InputBox
                                            label="New Room name"
                                            id="newroom"
                                            value={this.state.newroom}
                                            onChangeText={this.onChangeText}
                                        />
                                        </div>
                                    </div>
                                }
                                <div className="list">
                                    <SelectBox
                                        showSearch={false}
                                        disabled={!this.props.product.showavailabilty}
                                        label="Room"
                                        id="room"
                                        placeholder={"Choose"}
                                        value={this.state.room}
                                        options={this.props.cartRooms.map(room => ({ label: room, value: room })).concat([{ label: 'Add New Room', value: 'Neeeew' }])}
                                        onChangeText={this.onChangeText}
                                        optional="true"
                                    />
                                </div>
                            </div>
                            <div className="action-button">
                                <Button type="secondary" onClick={() => this.props.history.push(`/furniture-detailed-page/${this.props.product.maT_CODE}/${this.props.product.maT_DESC}`)}>View Details</Button>
                                {
                                    (!this.state.room || (this.state.room === 'Neeeew' && !this.state.newroom)) ? 
                                    <Popconfirm disabled={this.props.product.showavailabilty === 0 || (cartItem && (cartItem.quantity === this.props.product.qty))} title={"Are you sure you don't want to add this item to a particular room?"} okText="Yes Proceed" cancelText="Yes I will choose room" onConfirm={() => this.addItem() }>
                                        { this.getButtonText(false, cartItem) }
                                    </Popconfirm>
                                    :
                                    this.getButtonText(true, cartItem)
                                }
                                { this.props.context && this.props.alternateProducts && this.props.alternateProducts.length > 0 && (
                                    (!this.state.room || (this.state.room === 'Neeeew' && !this.state.newroom)) ?
                                    <Popconfirm disabled={this.props.product.showavailabilty === 0 || (cartItem && (cartItem.quantity === this.props.product.qty))} title={"Are you sure you don't want to add this item to a particular room?"} okText="Yes Proceed" cancelText="Yes I will choose room" onConfirm={() => this.addWithBedSetup() }>
                                        <Button disabled={this.props.product.showavailabilty === 0 || (cartItem && (cartItem.quantity === this.props.product.qty))} type="primary">Add with bed setup</Button>
                                    </Popconfirm>
                                    :
                                    <Button disabled={this.props.product.showavailabilty === 0 || (cartItem && (cartItem.quantity === this.props.product.qty))} type="primary" onClick={this.addWithBedSetup}>Add with bed setup</Button>
                                    ) }
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment >
        )
    }
}
function mapStateToProps(state) {
    return {
        cartItems : state.cart.cartItems,
        cartRooms: state.cart.cartRooms,
        context: state.login.context,
        alternateProducts: state.furniture.alternateProducts
    }
}
export default connect(mapStateToProps)(withRouter(QuickView))