import React from "react";
import { withRouter } from "react-router";
// import SimpleImageSlider from "react-simple-image-slider";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

class FurnitureSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const images = [{original: this.props.image, thumbnail: this.props.image }].concat(this.props.batch.map(item => ({original: item.imagePath, thumbnail: item.imagePath })));
    return (
      <React.Fragment>
        {/* <!--product Slider section starts--> */}
        <div className="product-slider">
            {/* <SimpleImageSlider
                width={450}
                height={400}
                images={images}
                showBullets={true}
                showNavs={true}
            /> */}
            <ImageGallery items={images} showThumbnails={false} showPlayButton={false} showFullscreenButton={false} infinite={false} />
        </div>
        {/* <!--product Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(FurnitureSlider);
