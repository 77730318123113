import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Divider, Result, Row, Spin, Tabs } from "antd";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { CartTypes } from "../../Actions/ActionType";
import { loadCartItems } from "../../Actions/CartActions";
import { getFurnitureInfo } from "../../Actions/FurnitureActions";
import {
  createOrder,
  getAddress,
  selectAddressFromCart,
} from "../../Actions/OrderActions";
import {
  getCustomerDetails,
  getCustomerInfo,
  getTradeCustomer,
  saveAddress,
} from "../../Actions/UserActions";
import CardDetails from "../PersonalDetails/CardDetails";
import "./MyCart.scss";
import OrderDetails from "./OrderDetails/OrderDetails";
import OrderSummary from "./OrderSummary/OrderSummary";
import SideNavigation from "./SideNavigation/SideNavigation";
import Delivery from "./StagingDetails/Delivery";
import Pickup from "./StagingDetails/Pickup";

const { Panel } = Collapse;
const { TabPane } = Tabs;

const genExtra = (items) => {
    let length = 0;
    if (items) {
        items.forEach(item => length = length + item.quantity)
    }
  return (
    <div className="total-items">
      <span>({length} items)</span>
    </div>
  );
};

const groupBy = (data, key) => {
  return data.reduce((acc, x) => {
    acc[x[key]] = [...(acc[x[key]] || []), x];
    return acc;
  }, {});
};

class MyCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coupanCode: "SOMECOUPAN",
      stagingType: "delivery",
      deliveryDetails: undefined,
      cardId: undefined,
      paymentType: "CARD",
      pickupDate: "",
      duration: 1,
      startDate: new Date(),
      items: [],
      activeTab: [1, 3],
      originalItems: {},
      showSuccess: false,
      orderId: undefined,
      verifyUserName: "",
      showVerifyProgress: false,
      deliveryInstructions: ''
    };
  }

  componentDidMount() {
    let isDatacontext = sessionStorage.getItem("itop-user-context")
      ? true
      : false;
    if (isDatacontext === true) {
      this.props.dispatch(getCustomerInfo());
      this.props.dispatch(getCustomerDetails());
      this.props.dispatch(getTradeCustomer());
      this.props.dispatch(loadCartItems()).then(() => {
        const cartItemIds = this.props.cartItems.map((item) => item.matCode);
        if (cartItemIds.length) {
          this.setState({ activeTab: [1, 2] });
          getFurnitureInfo(cartItemIds.join(",")).then((items) => {
            const originalItems = {};
            items &&
              items.forEach((item) => (originalItems[item.maT_CODE] = item));
            this.setState({ originalItems });
          });
        }
      });
      this.props.dispatch(getAddress()).then(() => {
        if (this.props.match.params.address) {
          this.props.dispatch(
            selectAddressFromCart(this.props.match.params.address)
          );
        } else if (sessionStorage.getItem("addressId")) {
          this.props.dispatch(
            selectAddressFromCart(sessionStorage.getItem("addressId"))
          );
        } else {
          this.props.dispatch(selectAddressFromCart(""));
        }
      });
    } else {
      const cartItems = sessionStorage.getItem("CartItems")
        ? JSON.parse(sessionStorage.getItem("CartItems"))
        : undefined;
      if (cartItems) {
        this.props.dispatch({
          type: CartTypes.SET_CART_ITEMS,
          payload: cartItems,
        });
        const cartItemIds = cartItems.map((item) => item.matCode);
        if (cartItemIds.length) {
          getFurnitureInfo(cartItemIds.join(",")).then((items) => {
            const originalItems = {};
            items &&
              items.forEach((item) => (originalItems[item.maT_CODE] = item));
            this.setState({ originalItems });
          });
        }
      }
    }
  }

  saveAddress = (address) => {
    return this.props.dispatch(saveAddress(address));
  };
  setDeliveryDetails = (deliveryDetails) => {
    this.setState({
      deliveryDetails: {
        deliveryDate: deliveryDetails.deliveryDate,
        preferredTiming: deliveryDetails.preferredTiming,
        timing: deliveryDetails.timing,
        address: this.props.selectedAddressId,
      },
      activeTab: [...this.state.activeTab, 3],
    });
  };
  setPickupDate = (pickupDate, pickupTime, timeText) => {
    this.props.dispatch(loadCartItems(true));
    this.setState({
      deliveryDetails: {
        deliveryDate: pickupDate,
        preferredTiming: pickupTime,
        address: "",
        timing: timeText,
      },
      activeTab: [...this.state.activeTab, 3],
    });
  };
  setDuration = (duration) => {
    this.setState({ duration });
  };
  setStartDate = (startDate) => {
    this.setState({ startDate });
  };
  savePaymentMethod = (cardId) => {
    if (cardId) {
      this.setState({ cardId, paymentType: "CARD" });
    } else {
      this.setState({ cardId: 0, paymentType: "PAYLATER" });
    }
  };
  setCoupanCode = (coupanCode) => {
    this.setState({ coupanCode });
  };
  verifyUser = (userDetails) => {
    this.setState({
      showVerifyProgress: true,
      verifyUserName: userDetails.contactName,
    });
  };
  placeOrder = () => {
    const order = {
      stagingType: this.state.stagingType,
      deliveryDetails: this.state.deliveryDetails,
      cardId: this.state.cardId,
      paymentType: this.state.paymentType,
      deliveryInstructions: this.state.deliveryInstructions
    };
    this.props.dispatch({ type: CartTypes.PLACING_ORDER, payload: true });
    this.props.dispatch(createOrder(order)).then((orderNumber) => {
      this.props.dispatch({ type: CartTypes.PLACING_ORDER, payload: false });
      if (orderNumber) {
        if (orderNumber) {
          this.setState({ showSuccess: true, orderId: orderNumber });
          this.props.dispatch({ type: CartTypes.SET_CART_ITEMS, payload: [] });
        }
      }
    });
  };
  render() {
    let isPickupAvailable =
      this.props.customerInfo && this.props.customerInfo.user_ID
        ? this.props.cartItems.length > 0 &&
          this.props.cartItems.length ===
            Object.values(this.state.originalItems).filter(
              (item) =>
                item.owneR_ID &&
                parseInt(item.owneR_ID) === this.props.customerInfo.user_ID
            ).length
        : false;
    const groupedCartItems = groupBy(this.props.cartItems, "room");
    const selectedAddress =
      this.props.selectedAddressId && this.props.savedAddresses
        ? this.props.savedAddresses.find(
            (add) =>
              add.partneR_FUNCTION.toString() ===
              this.props.selectedAddressId.toString()
          )
        : undefined;
    if (this.state.showSuccess) {
      return (
        <React.Fragment>
          <Result
            status="success"
            style={{ margin: "10%" }}
            title="Your order is successfully placed"
            subTitle={`Order ID: ${this.state.orderId}.`}
            extra={[
              <Button
                onClick={() => this.props.history.push("/undelivered-orders")}
                type="primary"
                key="console"
              >
                Check Order
              </Button>,
              <Button
                key="buy"
                onClick={() => this.props.history.push("/furniture")}
              >
                Explore Products
              </Button>,
            ]}
          />
        </React.Fragment>
      );
    }
    if (this.state.showVerifyProgress) {
      return (
        <React.Fragment>
          <Result
            status="success"
            style={{ margin: "10%" }}
            title="Your request submitted successfuly"
            subTitle={`Dear ${this.state.verifyUserName}, your verification is in progress. This process can take up to 24 hours. Or You can call me on +1-855-553-3768.`}
            extra={[
              <Button
                key="buy"
                onClick={() => this.props.history.push("/furniture")}
              >
                Explore Furniture
              </Button>,
            ]}
          />
        </React.Fragment>
      );
    }
    const originalItems = this.state.originalItems
      ? this.props.cartItems.map(
          (item) => this.state.originalItems[item.matCode]
        )
      : [];
    const hasOutOfStockItems =
      originalItems.filter((item) => item && !item.showavailabilty).length > 0;
    const cartItemsQuantity = {};
    const rooms = [];
    this.props.cartItems.map((item) => {
      if (!cartItemsQuantity[item.matCode]) {
        cartItemsQuantity[item.matCode] = item.quantity;
      } else {
        cartItemsQuantity[item.matCode] += item.quantity;
      }
      if (item.room && !rooms.includes(item.room)) {
        rooms.push(item.room);
      }
    });
    const quntityNotMatching =
      this.state.originalItems &&
      Object.values(this.state.originalItems).length > 0 &&
      Object.keys(cartItemsQuantity).length > 0
        ? Object.keys(cartItemsQuantity).filter(
            (key) => cartItemsQuantity[key] > this.state.originalItems[key] && this.state.originalItems[key].qty
          ).length > 0
        : false;
    let errorQuantityItems = [];
    if (quntityNotMatching) {
      errorQuantityItems = Object.keys(cartItemsQuantity).filter(
        (key) => cartItemsQuantity[key] > this.state.originalItems[key] && this.state.originalItems[key].qty
      );
    }
    return (
      <React.Fragment>
        <div className="inner-pages padding-40 top-right-wave light-bg">
          <div className="container mx-auto">
            <section className="my-cart-details">
              <Row
                gutter={[
                  { xs: 10, sm: 10, md: 10, lg: 20 },
                  { xs: 10, sm: 16, md: 10, lg: 10 },
                ]}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 6 }}
                  lg={{ span: 4 }}
                  xl={{ span: 4 }}
                  xll={{ span: 4 }}
                >
                  <SideNavigation context={this.props.context} />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 18 }}
                  lg={{ span: 20 }}
                  xl={{ span: 20 }}
                  xll={{ span: 20 }}
                >
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 18 }}
                      xl={{ span: 18 }}
                      xll={{ span: 18 }}
                    >
                      {hasOutOfStockItems && (
                        <span style={{ color: "red" }}>
                          One of the products is out of stock. Please check.
                        </span>
                      )}
                      {/* { errorQuantityItems && errorQuantityItems.length > 0 && 
                                            <span style={{ color: 'red' }}>
                                                {
                                                    errorQuantityItems.map(item => <div></div>)
                                                }
                                            </span>
                                        } */}
                      <div className="my-cart-tabs">
                        {/* accordian view starts */}
                        <Collapse
                          defaultActiveKey={this.state.activeTab}
                          activeKey={this.state.activeTab}
                          expandIconPosition="right"
                          expandIcon={({ isActive }) => (
                            <PlusOutlined rotate={isActive ? 90 : 0} />
                          )}
                          ghost
                        >
                          <Panel
                            header={`Order Summary`}
                            key={1}
                            extra={genExtra(this.props.cartItems)}
                          >
                            {/* {
                                                        this.props.cartItems.map(item => <OrderSummary product={item} />)
                                                    } */}
                            {!this.props.cartItems.length ? (
                              "No items found"
                            ) : (
                              <>
                                {this.props.cartItemsLoaded ? (
                                  <Spin tip="Please wait while we load the cart items." />
                                ) : (
                                  <>
                                    {Object.entries(groupedCartItems).map(
                                      ([groupName, items]) => {
                                        
                                        return (
                                          <div className="dep" key={groupName}>
                                            <h1 style={{ color: 'purple', fontSize: '22px' }}>{(!groupName || groupName === 'null') ? 'No room Specified' : groupName}</h1>
                                            {items.map((item) => (
                                              <div>
                                                {errorQuantityItems.includes(
                                                  item.matCode
                                                ) && (
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    Selected quantity is higher
                                                    than the available quantity.
                                                  </span>
                                                )}
                                                <OrderSummary
                                                  selectedAddress={
                                                    selectedAddress
                                                  }
                                                  context={this.props.context}
                                                  rooms={rooms}
                                                  originalItem={
                                                    this.state.originalItems[
                                                      item.matCode
                                                    ]
                                                  }
                                                  product={item}
                                                />
                                                <Divider />
                                              </div>
                                              
                                            ))}
                                          
                                          </div>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </Panel>
                          {this.props.cartItems.length > 0 && (
                            <Panel
                              disabled={
                                !this.props.context ||
                                this.props.cartItems.length === 0
                              }
                              header={
                                this.props.context
                                  ? "Address Details"
                                  : "Login / Signup to add address details"
                              }
                              key={2}
                            >
                              <Delivery
                                    customerDetails={this.props.customerDetails}
                                    isPickupAvailable={isPickupAvailable}
                                    locations={this.props.locations}
                                    handleSaveAddress={this.saveAddress}
                                    selectedAddress={
                                      this.props.selectedAddressId
                                    }
                                    savedAddresses={this.props.savedAddresses}
                                    handleSave={this.setDeliveryDetails}
                                    saveDeliveryInstructions={(deliveryInstructions) => this.setState({ deliveryInstructions })}
                                  />
                            </Panel>
                          )}
                          {this.props.cartItems.length > 0 && (
                            <Panel
                              disabled={
                                !this.props.context ||
                                this.props.cartItems.length === 0 ||
                                !this.state.deliveryDetails ||
                                !this.state.deliveryDetails.preferredTiming ||
                                !this.state.deliveryDetails.deliveryDate
                              }
                              header={
                                this.props.context
                                  ? "Payment Details"
                                  : "Login / Signup to make payment"
                              }
                              key={3}
                            >
                              <CardDetails
                                allowSelect={true}
                                handleSave={this.savePaymentMethod}
                              />
                            </Panel>
                          )}
                        </Collapse>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 6 }}
                      xl={{ span: 6 }}
                      xll={{ span: 6 }}
                    >
                      <OrderDetails
                        verifyUser={this.verifyUser}
                        deliveryDate={
                          this.state.deliveryDetails &&
                          this.state.deliveryDetails.deliveryDate
                            ? `${this.state.deliveryDetails.deliveryDate} ${this.state.deliveryDetails.timing}`
                            : ""
                        }
                        selectedAddress={
                          this.props.selectedAddressId &&
                          this.props.savedAddresses
                            ? this.props.savedAddresses.find(
                                (add) =>
                                  add.partneR_FUNCTION.toString() ===
                                  this.props.selectedAddressId.toString()
                              )
                            : undefined
                        }
                        costDetails={this.props.costDetails}
                        deliveryFee={this.props.deliveryFee}
                        isValid={
                          (this.state.cardId ||
                            this.state.paymentType === "PAYLATER") &&
                          this.state.deliveryDetails &&
                          this.props.cartItems.length > 0 &&
                          !hasOutOfStockItems &&
                          !quntityNotMatching
                        }
                        placeOrder={this.placeOrder}
                        handleCoupanApply={this.setCoupanCode}
                        cartItems={this.props.cartItems}
                        loggedUser={this.props.context ? true : false}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    cartItems: state.cart.cartItems,
    cartItemsLoaded: state.cart.cartItemsLoaded,
    savedAddresses: state.users.addresses,
    selectedAddressId: state.orders.selectedAddressId,
    deliveryFee: state.orders.deliveryFee,
    locations: state.users.locations,
    customerDetails: state.users.customerDetails,
    customerInfo: state.users.customerInfo,
    context: state.login.context,
    costDetails: state.cart.cartCostDetails,
  };
}
export default connect(mapStateToProps)(withRouter(MyCart));
