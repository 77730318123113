import React from 'react';
import { withRouter } from 'react-router';
import {
    BrowserRouter as Router,
    Link
} from 'react-router-dom';
import './RentYourFurnitures.scss';
import { Row, Col } from 'antd';

class RentYourFurnitures extends React.Component {

    render() {
        return (
            <React.Fragment>
                {/* rent your furniture starts */}
                <section className="rent-furniture" id='staging'>
                    <div className="container mx-auto">
                        <Row gutter={[22, 20]} align="center">
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <div className="section-title">
                                    <h2>
                                        Staging Operations We manage and deliver your items giving you online access. Plus, you can rent to others.
                                    </h2>
                                    <p>Let us help scale your business and make your life wonderful!! We handle the dirty work! We can store & manage your furniture in our warehouse, schedule pickup & delivery using our movers so all you need to do is select from your own items using our website! PLUS, you can select items for a COOP to earn money while you sleep.</p>
                                </div>
                                <Row align="center">
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
                                        <div className="custom-icon">
                                            <img src={require("../../../assets/images/rent-furniture/furniture.png")} alt="" />
                                            <p>We manage your furniture in a racked warehouse.</p>
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
                                        <div className="custom-icon">
                                            <img src={require("../../../assets/images/rent-furniture/cottage_black1.png")} alt="" />
                                            <p>Plan your next stage with your items via website.</p>
                                        </div>
                                    </Col>
                                    {/* <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
                                        <div className="custom-icon">
                                            <img src={require("../../../assets/images/rent-furniture/paid_black.svg")} alt="" />
                                            <p>You Earn</p>
                                        </div>
                                    </Col> */}
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
                                        <div className="custom-icon">
                                            <img src={require("../../../assets/images/how-it-works/shipping.svg")} alt="" />
                                            <p>We Deliver</p>
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
                                        <div className="custom-icon">
                                            <img src={require("../../../assets/images/rent-furniture/cottage_black.png")} alt="" />
                                            <p>You Stage</p>
                                        </div>
                                    </Col>

                                    {/* <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <div className="custom-icon">
                                            <img src={require("../../../assets/images/rent-furniture/shopping_cart.svg")} alt="" />
                                            <p>Give us your inventory, we will take photos, store and maintain your items. We'll add to our website and you can select what you need for your stagings AND you can earn money by entering COOP for free.</p>
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <div className="custom-icon">
                                            <img src={require("../../../assets/images/rent-furniture/airport_shuttle_black.svg")} alt="" />
                                            <p>No more rearranging tight warehouse space to see what you have, or dealing with unreliable movers. Just select what you need via website, and schedule our staff to delivery & place your items.</p>
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <div className="custom-icon">
                                            <img src={require("../../../assets/images/rent-furniture/paid_black.svg")} alt="" />
                                            <p>Let others rent your items on our website and make money while you sleep.</p>
                                        </div>
                                    </Col> */}
                                </Row>

                                {/* icons end */}
                                    <div className="call-to-action">
                                    <Row align="center">
                                        <Col xs={{ span: 24 }} sm={{ span:24 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <img style={{ float: 'right'}} src={require("../../../assets/images/rent-furniture/paid_black.svg")} alt=""/>
                                        </Col>
                                        <Col style={{ paddingTop: '2%' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                            <p><b>Bonus:</b> Earn money if you let	other professionals rent your items	via the COOP.</p>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="call-to-action">
                                    <Row align="center">
                                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                            <p>This is a great way to grow and scale your business. Web-based inventory included - which shows you what you have at a glance, what is checked out, and with no hassles of managing a warehouse or finding and hiring reliable movers. Have more houses than you have inventory? You can rent Staging Depot or COOP inventory so you don't have to buy more furniture.</p>
                                        </Col>
                                    </Row>
                                </div>
                                {/* cta ends */}
                            </Col>
                        </Row>
                    </div>
                </section>
                {/* rent your furniture ends */}
            </React.Fragment>
        )
    }
}
export default withRouter(RentYourFurnitures)