import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';
import Slider from "react-slick";
import FurnitureItem from '../../../Components/Furniture/FurnitureItem';

const { Text } = Typography;

const OffersForyouslidersettings = {
    arrows: true,
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};

class OffersForyou extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="offers-foryou">
                    {/* <!--Slider Item starts--> */}
                    <Slider {...OffersForyouslidersettings}>
                        {
                            this.props.furniture.map(item => {
                                return <FurnitureItem item={item} offer />
                            })
                        }

                    </Slider>
                    {/* <!--Slider Item ends--> */}
                </div>

            </React.Fragment>
        )
    }
}
export default withRouter(OffersForyou)