import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Row, Col, Button } from "antd";
import "../MyDashboard.scss";
import SiderMenu from "../SiderMenu";
import InputBox from "../../../Components/InputBox/InputBox";
import { getOrders } from "../../../Actions/OrderActions";
import OrderCard from "../OrderCard";

class OrderHistory extends React.Component {
  constructor() {
    super();
    this.state = {
      searcbyOrderno: "",
    };
  }
  componentDidMount() {
    let isDatacontext = sessionStorage.getItem("itop-user-context")
      ? true
      : false;
    if (isDatacontext === true) {
      this.props.dispatch(getOrders());
    }
  }
  onChangeText = (value, type) => {
    this.setState({ [type]: value });
  };
  render() {
    return (
      <React.Fragment>
        <div className="inner-pages padding-40 top-right-wave light-bg">
          <div className="container mx-auto">
            <div className="my-dashboard margin-bottom20">
              <h1>My Dashboard</h1>
            </div>
            <Row
              gutter={[
                { xs: 10, sm: 15, md: 15, lg: 20 },
                { xs: 10, sm: 16, md: 10, lg: 10 },
              ]}
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 5 }}
                lg={{ span: 5 }}
              >
                <SiderMenu />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 19 }}
                lg={{ span: 19 }}
              >
                <div className="card my-orders light-color">
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 18 }}
                      lg={{ span: 18 }}
                    >
                      <h2>Order History</h2>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 6 }}
                      lg={{ span: 6 }}
                    >
                      <div className="form-fields">
                        <InputBox
                          placeholder="Search by Product name or order no."
                          id="searcbyOrderno"
                          value={this.state.searcbyOrderno}
                          onChangeText={this.onChangeText}
                          type="text"
                          optional="true"
                          suffix={"fa fa-search"}
                        />
                      </div>
                    </Col>
                  </Row>

                  {this.props.orders
                    .filter(
                      (order) =>
                        order.ordeR_NO === order.ref_No &&
                        order.ordeR_NO.includes(this.state.searcbyOrderno)
                    )
                    .map((order) => {
                      return (
                        <OrderCard
                          ordersHierarchy={this.props.ordersHierarchy}
                          order={order}
                        />
                      );
                    })}
                  {this.props.orders.length === 0 && (
                    <div className="no-product-available">
                      <Row gutter={[8, 8]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 12 }}
                          md={{ span: 18 }}
                          lg={{ span: 18 }}
                        >
                          <h3>You dont have any orders !</h3>
                          <p>
                            Explore the furniture and order some awesome stuff.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    orders: state.orders.orders,
    ordersHierarchy: state.orders.ordersHierarchy,
  };
}
export default connect(mapStateToProps)(withRouter(OrderHistory));
