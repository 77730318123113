import axios from 'axios';
import AppConfig from '../config';
import { OrderTypes } from './ActionType';
import { AddressTypes } from './ActionType';
import { loadCartItems } from './CartActions';
import { getAxios } from './setAuthorizationToken';

const API_BASE_URL = AppConfig.API_BASE_URL;


export const getOrders = () => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetOrderHistory`)
            .then((res) => {
                if (res.data && res.data.orderHistory) {
                    dispatch({ type: OrderTypes.SET_ORDERS, payload: res.data.orderHistory })
                } else {
                    dispatch({ type: OrderTypes.SET_ORDERS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting Orders Details failed.' } })
            });
    }
}

export const getOrderItems = (orderid) => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetOrderDetails/${orderid}`)
            .then((res) => {
                if (res.data && res.data.orderHistory) {
                    return res.data;
                } else {
                    return false;
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting Orders Details failed.' } })
                return false;
            });
    }
}

export const createOrder = (order) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/CreateOrder`, order)
            .then((res) => {
                if (res.data && res.data.id) {
                    dispatch({ type: OrderTypes.ADD_ORDER, payload: res.data })
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: "Order placed succesfully" } })
                    return res.data.ordeR_NO;
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: res.data && res.data.message } })
                    return false;
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Create order failed.' } })
                return false;
            });
    }
}

export const createAddress = (address) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/CreateAddress`, address)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: AddressTypes.ADD_ADDRESS, payload: res.data })
                    dispatch(getAddress())
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: res.data.message } })
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Add Address failed.' } })
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Add Address failed.' } })
            });
    }
}

export const getAddress = () => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetAddress`)
            .then((res) => {
                if (res.data && res.data.adddressList) {
                    dispatch({ type: AddressTypes.SET_ADDRESS, payload: res.data.adddressList })
                } else {
                    dispatch({ type: AddressTypes.SET_ADDRESS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting Address Details failed.' } })
            });
    }
}

export const getOpenOrderList = (prompt) => {
    return function (dispatch) {
        dispatch({ type: OrderTypes.GET_OPEN_ORDERS_LIST })
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetOpenOrderList`)
            .then((res) => {
                if (res.data && res.data.openOrderList) {
                    dispatch({ type: OrderTypes.SET_OPEN_ORDERS_LIST, payload: res.data.openOrderList })
                    if (prompt) {
                        if (res.data.openOrderList.length > 0) {
                            dispatch({ type: OrderTypes.PROMPT_OPEN_ORDERS_SELECTION, payload: true })
                        } else {
                            window.location.reload()
                        }
                    }
                } else {
                    dispatch({ type: OrderTypes.SET_OPEN_ORDERS_LIST, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting draft orders failed.' } })
            });
    }
}

export const getOrderHistory = () => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetOpenOrderList`)
            .then((res) => {
                if (res.data && res.data.openOrderList) {
                    dispatch({ type: OrderTypes.SET_OPEN_ORDERS_LIST, payload: res.data.openOrderList })
                } else {
                    dispatch({ type: OrderTypes.SET_OPEN_ORDERS_LIST, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting draft orders failed.' } })
            });
    }
}

export const getDeliveryPrices = () => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetDeliveryFees`)
            .then((res) => {
                if (res.data && res.data.deliveryFees) {
                    dispatch({ type: OrderTypes.SET_DELIVERY_FEE, payload: res.data.deliveryFees })
                } else {
                    dispatch({ type: OrderTypes.SET_DELIVERY_FEE, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Error in getting delivery details' } })
            });
    }
}

export const saveOpenOrder = (model) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/SaveOpenOrder`, model)
            .then((res) => {
                if (res.data && res.data) {
                    if (res.data.id) {
                        dispatch({ type: OrderTypes.SAVE_OPEN_ORDERS })
                        dispatch(loadCartItems(false))
                        dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'draft orders saved successfully' } })
                        return true;
                    } else {
                        dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: res.data.message ? res.data.message : 'Error in saving draft order'  } })
                        return false;
                    }
                    
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'draft orders save failed.' } })
                    return false;
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'draft orders save failed.' } })
            });
    }
}

export function selectAddressFromCart(addressId) {
    return function (dispatch) {
        sessionStorage.setItem("addressId", addressId);
        return dispatch({ type: OrderTypes.SELECTED_ADDRESS_ID_FROM_CART, payload: addressId })
    }
}

export function getPickupDates() {
    return getAxios().get(`${API_BASE_URL}/api/CustomerPortal/GetPickupDate`)
        .then((res) => {
            if (res.data && res.data && res.data.pickupDates) {
                return res.data.pickupDates.map(date => ({ date: date.pickUpDate, timings: date.pickUp }) );
            } else {
                return [];
            }
        })
        .catch((err) => {
            return undefined;
        });
}

export function getPickupDatesWithZip(zipcode) {
    return getAxios().get(`${API_BASE_URL}/api/CustomerPortal/GetPickupDate?zipCode=${zipcode}`)
        .then((res) => {
            if (res.data && res.data && res.data.deliveryDates) {
                return res.data.deliveryDates.map(date => ({ date: date.deliveryDate, timings: date.deilvery }) );
            } else {
                return [];
            }
        })
        .catch((err) => {
            return undefined;
        });
}

export function getDeliveryDates(zipcode) {
    return getAxios().get(`${API_BASE_URL}/api/CustomerPortal/GetDeliveryDate?zipCode=${zipcode}`)
        .then((res) => {
            if (res.data && res.data && res.data.deliveryDates) {
                return res.data.deliveryDates.map(date => ({ date: date.deliveryDate, timings: date.deilvery }) );
            } else {
                return [];
            }
        })
        .catch((err) => {
            return undefined;
        });
}

export const getCustomerDetails = () => {
    return function (dispatch) {
        return getAxios(dispatch).get(`${API_BASE_URL}/api/CustomerPortal/GetCustomer`)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: OrderTypes.GET_DETAILS, payload: res.data })
                } else {
                    dispatch({ type: OrderTypes.GET_DETAILS, payload: [] })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Getting Details failed.' } })
            });
    }
}

export const updateOpenOrder = (model) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/UpdateOpenOrder`, model)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: OrderTypes.UPDATE_OPEN_ORDER })
                    dispatch(getOpenOrderList())
                    dispatch(loadCartItems(false));
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Draft order updated successfully' } })
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Draft order updating failed.' } })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Draft order updating failed.' } })
            });
    }
}

export const removeOpenOrder = (orderNo) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/RemoveOpenOrder?orderNo=${orderNo}`)
            .then((res) => {
                if (res.data && res.data && res.data.status) {
                    dispatch({ type: OrderTypes.DELETE_OPEN_ORDER, orderNo })
                    dispatch(getOpenOrderList())
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Draft order deleted successfully' } })
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Failed to delete Draft order.' } })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Failed to delete Draft order.' } })
            });
    }
}

export const deleteAddress = (AddressID) => {
    return function (dispatch) {
        dispatch({ type: OrderTypes.SELECTED_ADDRESS_ID_FROM_CART, payload: '' })
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/DeleteAddress?AddressID=${AddressID}`)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: AddressTypes.DELETE_ADDRESS, payload: res.data })
                    dispatch(getAddress());
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Address deleted successfully' } })
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Delete Address failed.' } })
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Delete Address failed.' } })
            });
    }
}

export const editAddress = (model) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/EditAddress`, model)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch({ type: AddressTypes.EDIT_ADDRESS, payload: res.data })
                    dispatch(getAddress());
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Address edited successfully' } })
                } else {
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Edit Address failed.' } })
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Edit Address failed.' } })
            });
    }
}

export const cancelOrder = (id) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/CancelOrder?orderNo=${id}`)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch(getOrders())
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Order cancelled successfully' } })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Cancel order failed.' } })
            });
    }
}

export const returnOrder = (obj) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/ReturnOrder`, obj)
            .then((res) => {
                if (res.data && res.data.id) {
                    dispatch(getOrders())
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: res.data.message } })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Return order failed.' } })
            });
    }
}

export const placeOrder = (id, overwrite) => {
    return function (dispatch) {
        return getAxios(dispatch).post(`${API_BASE_URL}/api/CustomerPortal/OpenOrderToCart?orderNo=${id}&overwrite=${overwrite}`)
            .then((res) => {
                if (res.data && res.data) {
                    dispatch(getOpenOrderList())
                    dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'SUCCESS', message: 'Order moved to cart successfully' } })
                }
            })
            .catch((err) => {
                dispatch({ type: 'SET_NOTIFICATION', notification: { type: 'ERROR', message: 'Moving to cart failed.' } })
            });
    }
}