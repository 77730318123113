import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button, Popover, Checkbox, Popconfirm, Divider } from 'antd';
import './OpenOrders.scss';
import { InputNumber } from 'antd';
import { updateOpenOrder, placeOrder } from '../../Actions/OrderActions';
import { connect } from 'react-redux';

class OrderDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            orderData: [],
            // selectedQuantity: this.props.data && this.props.data.qty,
            symbolsArr: ["e", "E", "+", "-", "."]
        }
    }
    // popover
    hide = () => {
        this.setState({
            visible: false,
        });
    };

    handleVisibleChange = visible => {
        this.setState({ visible });
    };

    componentDidMount() {
        if (this.props.data) {
            this.setState({
                orderData: this.props.data && this.props.data.items,
            });
        }
    }

    handleQuantityChange = (value, id) => {
        let newArray = [...this.state.orderData]
        let fIndex = newArray.findIndex(m => m.iteM_NO === id);
        const isInteger = /^[0-9]+$/;
        if (value === '' || isInteger.test(value)) {
            if (value !== null) {
                newArray[fIndex].qty = value
                this.setState({
                    orderData: newArray
                })
            }
        }
        if (value === null) {
            newArray[fIndex].qty = 1
            this.setState({
                orderData: newArray
            })
        }
    }

    handleDeleteItem = (item) => {
        this.setState({
            orderData: this.state.orderData.filter(v => v.iteM_NO !== item.iteM_NO).map(v => v)
        });
    }

    handleUpdateOpenOrder = () => {
        let items = []
        this.state.orderData.forEach((item, index) => {
            items = [...items, { "matcode": item.maT_CODE, "room": item.room, "qty": typeof item.qty === "number" ? item.qty : Number(item.qty.split(".")[0]) }]
        })
        let model = {
            "orderNo": this.props.data && this.props.data.ordeR_NO,
            "customerId": 0,
            "addressType": this.props.data.addressDetails && this.props.data.addressDetails.addressType,
            "items": items
        }
        this.props.dispatch(updateOpenOrder(model))
    }

    placeYourOrder = (id, overwrite) => {
        this.props.dispatch(placeOrder(id, overwrite)).then(() => {
            const addressId = this.props.data.addressDetails ? this.props.data.addressDetails.addressType : '';
            this.props.history.push(`/my-cart/${addressId}`);
        })
    };

    groupBy = (data, key) => {
        return data.reduce((acc, x) => {
            acc[x[key]] = [...(acc[x[key]] || []), x];
            return acc;
        }, {});
    }

    render() {
        let orderNumber = '';

        this.state.orderData && this.state.orderData.forEach(item => {
            orderNumber = item.ordeR_NO
        });
       
        const groupedCartItems = this.groupBy(this.state.orderData, 'room')
        return (
            <React.Fragment>
                <div className="address-details">
                    <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} className="text-right">
                            {/* <h2>Would you like to modify this order?</h2> */}
                             { this.props.cartItems && this.props.cartItems.length > 0 ? 
                                <Popconfirm title={'Clicking on this will overwrite all items in ACTIVE cart. If you want to save ACTIVE cart first, please go to cart and save as a Draft Order.'} okText="Add cart items to this list" cancelText="Delete cart items" onConfirm={() => this.placeYourOrder(orderNumber, false)} onCancel={() => this.placeYourOrder(orderNumber, true)}>
                                    <Button type="primary">Edit Order</Button>
                                </Popconfirm>
                                :
                                <Button type="primary" onClick={() => this.placeYourOrder(orderNumber, true)}>Edit Order</Button>
                             }
                        </Col>
                    </Row>
                </div>

                <div className="orders-containers">
                    {
                        Object.entries(groupedCartItems).map(([groupName, items], idx) => {
                            return <div key={`item-ao-${idx}`}>
                            <h1 style={{ color: 'purple', fontSize: '22px' }}>{groupName === 'null' ? 'No room Specified' : groupName}</h1>
                            { items && items.map((list, idx) =>
                            <div key={`item-oo-${idx}`} className="ordered-items">
                                <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                    <Col xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 1 }} lg={{ span: 1 }}>
                                        {/* <Checkbox /> */}
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                        <img src={list.imagePath} alt="Product Image" style={{ maxWidth: "100%" }} />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 13 }} lg={{ span: 13 }}>
                                        <h4>{list.maT_DESC}</h4>
                                        <p>{list.remark1}</p>
                                        <div className="specfications">
                                            <div className="rent-price">
                                                <h5>Rent</h5>
                                                <span>${list.monthlyRent}/mon</span>
                                            </div>
                                            <div className="quantity">
                                                <h5>Quantity</h5>
                                                {/* <InputNumber
                                                    min={1}
                                                    max={10}
                                                    value={list.qty}
                                                    defaultValue={list.qty}
                                                    onChange={(event) => this.handleQuantityChange(event, list.iteM_NO)}
                                                    onKeyDown={e => this.state.symbolsArr.includes(e.key) && e.preventDefault()} readOnly
                                                /> */}
                                                <label defaultValue={list.qty} style = {{marginLeft:"17px"}}>{Math.floor(list.qty)}</label>
                                            </div>
                                            <div className="room">
                                                <h5>Room</h5>
                                                <span>{list.room}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                        <div className="edit-delete-action text-right">
                                            {/* <Button type="link"><i className="flaticon-edit" /></Button> */}
                                            {
                                                this.state.orderData && this.state.orderData.length === 1 ? ""
                                                    :
                                                    <Button type="link" onClick={() => this.handleDeleteItem(list)}><i className="flaticon-delete-1" /></Button>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>)
                            }
                            <Divider />
                            </div>
                        })
                    }
                </div>
                <div className="payable-amount">
                    <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                            <h3>Payable Amount</h3>
                            {
                                this.props.costDetails && this.props.costDetails.map((costItem, idx) => costItem.isTotal === "0" && costItem.description !== "Trade Discount" && <div key={`cost-${idx}`} className="monthly-rental">{costItem.description}<span>$ {parseFloat(costItem.amount).toFixed(2)}</span></div> )
                            }
                            {
                                this.props.costDetails && this.props.costDetails.map((costItem, idx) => costItem.description === "Trade Discount" && <div key={`discount-${idx}`} className="monthly-rental" style={{ color: "red" }}>{costItem.description}<span style={{ color: "red" }}>$ {parseFloat(costItem.amount).toFixed(2)}</span></div> )
                            }
                            <div className="sub-total" style={{ float: "right"}}>
                                <h3>Total Amount</h3>
                                {
                                    this.props.costDetails && this.props.costDetails.map((costItem, idx) => costItem.isTotal === "1" && <span key={`total-${idx}`}>$ {parseFloat(costItem.amount).toFixed(2)}</span> )
                                }
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} className="text-center">
                            {
                                this.props.cartItems && this.props.cartItems.length > 0 ? 
                                <Popconfirm title={'Clicking on this will overwrite all items in ACTIVE cart. If you want to save ACTIVE cart first, please go to cart and save as a Draft Order.'} okText="Add cart items to this list" cancelText="Delete cart items" onConfirm={() => this.placeYourOrder(orderNumber, false)} onCancel={() => this.placeYourOrder(orderNumber, true)}>
                                    <Button type="primary">Proceed to checkout</Button>
                                </Popconfirm>
                                :
                                <Button type="primary" onClick={() => this.placeYourOrder(orderNumber, true)}>Proceed to checkout</Button>
                            }
                        </Col>
                    </Row>
                </div>

            </React.Fragment >
        )
    }
}
export default connect()(withRouter(OrderDetails))