import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Button, Col } from "antd";
import {
  addItemToCart,
  addItemToTempCart,
  removeItemFromCart,
  removeItemFromTempCart,
  updateItemInCart,
  updateItemToTempCart
} from "../../../Actions/CartActions";
import { InputNumber } from "antd";
import ModalBox from "../../../Components/ModalBox/ModalBox";
import SelectBox from "../../../Components/SelectBox/SelectBox";
import InputBox from "../../../Components/InputBox/InputBox";
import { updateRecentViewedDetails } from "../../../Actions/WishListActions";

class OrderSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedQuantity: this.props.product.quantity,
      symbolsArr: ["e", "E", "+", "-", "."],
      quickView: false,
      quickViewProduct: undefined,
      editItem: null,
      room: "",
    };
    String.prototype.initCap = function () {
      return this.toLowerCase().replace(/(?:^|\b)[a-z]/g, function (m) {
        return m.toUpperCase();
      });
    };
  }

  updateCartItem = (value) => {
    this.setState({ selectedQuantity: this.state.selectedQuantity });
    const room =
      this.state.room === "Neeeew" ? this.state.newroom : this.state.room;
    let model = {
      matCode: this.props.product.itemId,//this.props.product.matCode,
      quantity: this.state.selectedQuantity,
      room,
    };
    if (room === this.props.product.room) {
      if (this.state.selectedQuantity !== this.props.product.quantity) {
        if (this.props.context) {
          this.props.dispatch(
            updateItemInCart(
              model,
              this.props.selectedAddress
                ? this.props.selectedAddress.pincode
                : undefined
            )
          ).then((isSuccess) => {
            if (isSuccess) {
              this.setState({ editItem: null })
            }
          });
        } else {
          this.props.dispatch(updateItemToTempCart(this.props.product, model.quantity, model.room));
          this.setState({ editItem: null })
        }
      }
      else {
        this.setState({ editItem: null })
      }
    } else {
      // this.removeCartItem(this.props.product.itemId, false).then(() => {
      if (this.props.context) {
        let newModel = {
          matCode: this.props.product.matCode,
          quantity: this.state.selectedQuantity,
          room,
        };
        this.removeCartItem(this.props.product.itemId, false).then(() => {
          this.props.dispatch(addItemToCart(newModel, true))
        })
      } else {
        // this.props.dispatch(addItemToTempCart(this.props.product, model.quantity, model.room));
        this.props.dispatch(updateItemToTempCart(this.props.product, model.quantity, model.room));
        this.setState({ editItem: null })
      }
      // })
    }
  };

  removeCartItem = (itemId, reload = true) => {
    if (this.props.context) {
      return this.props.dispatch(removeItemFromCart(itemId, reload));
    } else {
      return this.props.dispatch(removeItemFromTempCart(this.props.product.matCode));
    }
  };
  editCartItem = () => {
    this.setState({
      editItem: this.props.product,
      room: this.props.rooms.filter(v => v === this.props.product.room) && this.props.rooms.filter(v => v === this.props.product.room)[0]
    });
  };
  handleOpenQuickView = (item) => {
    this.setState({ quickView: true, quickViewProduct: item });
    let model = {
      matCode: item.maT_CODE,
    };
    if (this.props.context) {
      this.props.dispatch(updateRecentViewedDetails(model));
    }
  };
  onCancel = () => {
    this.setState({ quickView: false, quickViewProduct: undefined });
  };
  onChangeText = (value, type) => {
    this.setState({ [type]: value });
  };
  render() {
    return (
      <React.Fragment>
        <div className="orders-containers">
          <div className="ordered-items">
            <Row gutter={[20, 20]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 5 }}
                lg={{ span: 5 }}
              >
                {/* <img src={this.props.product.item.imagePath} alt={this.props.product.item.maT_DESC} /> */}
                <Link
                  to={`/${isNaN(this.props.product.matCode)
                    ? "bundles-detailed-page"
                    : "furniture-detailed-page"
                    }/${this.props.product.matCode}/${this.props.product.productName
                    }`}
                >
                  <img
                    style={{ maxWidth: "100%" }}
                    src={
                      this.props.originalItem
                        ? this.props.originalItem.thumbnailImagePath
                        : this.props.product.imagePath
                    }
                    alt={this.props.product.productDesc}
                  />
                </Link>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 17 }}
                lg={{ span: 17 }}
              >
                <Link
                  to={`/${isNaN(this.props.product.matCode)
                    ? "bundles-detailed-page"
                    : "furniture-detailed-page"
                    }/${this.props.product.matCode}/${this.props.product.productName
                    }`}
                >
                  <h4>{this.props.product.productName}</h4>
                </Link>
                {this.props.originalItem &&
                  !this.props.originalItem.showavailabilty ? (
                  <span style={{ color: "red" }}>Out of stock</span>
                ) : (
                  this.props.originalItem &&
                  this.props.product.quantity > this.props.originalItem.qty && (
                    <span style={{ color: "red" }}>
                      Selected quantity is higher than available quantity
                    </span>
                  )
                )}
                <p>{this.props.product.productDesc}</p>
                <div className="specfications">
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 6 }}
                      xl={{ span: 6 }}
                      xxl={{ span: 6 }}
                    >
                      <div className="rent-price">
                        <h5>Rent</h5>
                        {/* <span>${this.props.product.item.monthlyrent + 12 }/mon</span> */}
                        <span>${this.props.product.price}/mon</span>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 6 }}
                      xl={{ span: 6 }}
                      xxl={{ span: 6 }}
                    >
                      <div className="quantity">
                        <h5>Quantity</h5>
                        <span>{this.props.product.quantity}</span>
                        {/* <span>{this.props.product.quantity}</span> */}
                        {/* <InputNumber
                          min={1}
                          max={
                            this.props.originalItem
                              ? this.props.originalItem.qty
                              : 1
                          }
                          parser={(value) => {
                            return value.slice(0, 2);
                          }}
                          value={this.state.selectedQuantity}
                          defaultValue={this.props.product.quantity}
                          onChange={this.handleQuantityChange}
                          onKeyDown={(e) =>
                            this.state.symbolsArr.includes(e.key) &&
                            e.preventDefault()
                          }
                        /> */}
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                      xxl={{ span: 12 }}
                    >
                      <div className="choose-room">
                        <h5>Room</h5>
                        <span>{this.props.product.room}</span>
                        {/* <SelectBox
                          style={{ width: "250px" }}
                          id="room"
                          value={this.props.product.room}
                          showSearch={false}
                          readonly
                          options={this.props.rooms
                            .map((room) => ({ label: room, value: room }))
                            .concat([{ label: "New Room", value: "Neeeew" }])}
                          optional="true"
                          onChangeText={this.handleRoomChange}
                        /> */}
                      </div>
                    </Col>
                  </Row>
                  <div class="itemsList" style={{ float: "right" }}>
                    {this.props.product && this.props.product.itemlist === 1 && (
                      <Button
                        type="secondary"
                        onClick={() =>
                          this.handleOpenQuickView(this.props.product)
                        }
                      >
                        Items List
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 2 }}
                lg={{ span: 2 }}
              >
                <div className="edit-delete-action">
                  <Button
                    type="link"
                    onClick={() => {
                      this.removeCartItem(this.props.product.itemId);
                    }}
                  >
                    <i
                      alt="Delete Cart Item"
                      style={{ color: "red" }}
                      className="flaticon-delete-1"
                    />
                  </Button>
                </div>
                <div className="edit-delete-action">
                  <Button
                    type="link"
                    onClick={() => {
                      this.editCartItem(this.props.product.itemId);
                    }}
                  >
                    <i
                      alt="Edit Cart Item"
                      style={{ color: "red" }}
                      className="flaticon-edit-1"
                    />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          {/* list ends here */}
          {this.props.originalItem && (
            <ModalBox
              visible={this.state.quickView}
              width={854}
              wrapClassName="main-search-area"
              onCancel={this.onCancel}
              footer={null}
              destroyOnClose
            >
              <div>
                <Row>
                  {this.props.originalItem.batch &&
                    this.props.originalItem.batch.map((item) => {
                      return (
                        <Col
                          style={{ margin: "1%", border: "1px solid #ccc" }}
                          xs={{ span: 24 }}
                          sm={{ span: 11 }}
                          md={{ span: 11 }}
                          lg={{ span: 11 }}
                        >
                          <img
                            src={item.imagePath}
                            style={{ width: "200px", height: "200px" }}
                          />
                          <br />
                          <span style={{ fontWeight: "bold" }}>
                            {item.qty} X {item.maT_DESC}
                          </span>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </ModalBox>
          )}
          {this.state.editItem && (
            <ModalBox
              title="Change Quantity / Room"
              width={350}
              destroyOnClose
              visible={this.state.editItem}
              onOk={this.updateCartItem}
              onCancel={() => this.setState({ editItem: null })}
            >
              <div className="quantity">
                <h5>
                  Quantity (Max{" "}
                  {this.props.originalItem ? this.props.originalItem.qty : 1})
                </h5>
                {/* <span>{this.props.product.quantity}</span> */}
                {/* <span>{this.props.product.quantity}</span> */}
                <InputNumber
                  min={1}
                  max={
                    this.props.originalItem ? this.props.originalItem.qty : 1
                  }
                  parser={(value) => {
                    return value.slice(0, 2);
                  }}
                  value={this.state.selectedQuantity}
                  defaultValue={this.props.product.quantity}
                  onChange={(selectedQuantity) =>
                    this.setState({ selectedQuantity })
                  }
                  onKeyDown={(e) =>
                    this.state.symbolsArr.includes(e.key) && e.preventDefault()
                  }
                />
              </div>
              <div className="list">
                <SelectBox
                  showSearch={false}
                  label="Room"
                  id="room"
                  placeholder={"Choose"}
                  value={this.state.room}
                  options={this.props.rooms
                    .map((room) => ({ label: room, value: room }))
                    .concat([{ label: "New Room", value: "Neeeew" }])}
                  onChangeText={this.onChangeText}
                  optional="true"
                />
              </div>
              {this.state.room === "Neeeew" && (
                <div className="select-room">
                  <div className="form-fields">
                    <InputBox
                      label="New Room name"
                      id="newroom"
                      value={this.state.newroom}
                      onChangeText={this.onChangeText}
                    />
                  </div>
                </div>
              )}
            </ModalBox>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default connect()(withRouter(OrderSummary));
