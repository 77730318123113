import React from 'react';
import { withRouter } from 'react-router';
import Slider from "react-slick";

const ItemsIncludedsettings = {
    arrows: true,
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2
};

class ItemsIncluded extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="similar-products">
                    <Slider {...ItemsIncludedsettings}>
                        {
                            this.props.items.map(mat => {
                                return <div className="item">
                                <img height="90" width="120" src={mat.imagePath} alt={mat.maT_CODE} />
                            </div>
                            })
                        }


                    </Slider>
                </div>
            </React.Fragment>

        )
    }
}
export default withRouter(ItemsIncluded)