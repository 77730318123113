import { AddressTypes, CardTypes, UserType } from '../Actions/ActionType';

const initialState = {
  userLocation: localStorage.getItem('userlocation') ? JSON.parse(localStorage.getItem('userlocation')) : undefined,
  locations: [],
  addresses: [],
  saveAddressSuccess : {},
  testimonials: [],
  customerInfo: {},
  customerTradeInfo: undefined,
  cards: []
};


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CardTypes.LOAD_CARDS: {
      return {
        ...state,
        cards: action.payload
      }
    }
    case UserType.SET_TESTIMONIALS: {
      return {
        ...state,
        testimonials: action.payload
      }
    }
    case UserType.SET_CUSTOMER_TRADE_INFO: {
      return {
        ...state,
        customerTradeInfo: action.payload
      }
    }
    case UserType.SET_CUSTOMER_DETAILS: {
      return {
        ...state,
        customerDetails: action.payload
      }
    }
    case UserType.SET_CUSTOMER_INFO: {
      return {
        ...state,
        customerInfo: action.payload
      }
    }
    case UserType.SET_LOCATIONS: {
      return {
        ...state,
        locations: action.payload
      }
    }
    case UserType.SET_USER_LOCATION: {
      localStorage.setItem('userlocation', JSON.stringify(action.payload));
      return {
        ...state,
        userLocation: action.payload
      }
    }
    case AddressTypes.SET_ADDRESS: {
      return {
        ...state,
        addresses: action.payload
      }
    }
    case UserType.ADD_ADDRESS: {
      return {
        ...state,
        saveAddressSuccess: action.payload
      }
    }
    default: {
      return state;
    }
  }
}