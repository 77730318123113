import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Button } from 'antd';
import '../MyDashboard.scss';
import SiderMenu from '../SiderMenu';
import InputBox from '../../../Components/InputBox/InputBox';

class Payment extends React.Component {
    constructor() {
        super()
        this.state = {
            searchPayments: '',
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    render() {
        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <div className="my-dashboard margin-bottom20">
                            <h1>My Dashboard</h1>
                        </div>
                        <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <SiderMenu />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                <div className="card my-orders light-color">
                                    <Row>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                            <h2>Payment</h2>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="form-fields">
                                                <InputBox
                                                    placeholder="search Payments."
                                                    id="searchPayments"
                                                    value={this.state.searchPayments}
                                                    onChangeText={this.onChangeText}
                                                    type="text"
                                                    optional="true"
                                                    suffix={"fa fa-search"}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(Payment)