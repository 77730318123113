import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import InputBox from '../../Components/InputBox/InputBox';
import { Row, Col, Tabs, Button, Divider, Spin, Checkbox, Popover } from 'antd';
import './SigninSignup.scss';
// import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import { getSocialLoginUrls, apiLogin, requestOTP, loginWithOTP, userSignup, verifyMobile, verifyEmail } from '../../Actions/LoginActions'
import { connect } from 'react-redux';
import { UtilsType } from '../../Actions/ActionType';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { createTradeGroupUser } from '../../Actions/UserActions';
import SelectBox from '../../Components/SelectBox/SelectBox';

const { TabPane } = Tabs;


class SigninSignup extends React.Component {
    constructor() {
        super()
        this.state = {
            defaultActiveKey: [],
            mobileNumber: '',
            fullName: '',
            emailId: '',
            phoneNumber: '',
            loading: false,
            otp: '',
            otpGenerated: false,
            isTradeGroupUser: true,
            tradeGroupHelpVisible: false,
            companyName: '',
            website: '',
            companyAddress1: '',
            companyAddress2: '',
            city: '',
            state: '',
            zip: '',
            comments: '',
            userType: '',
            verifyMobileError: {},
            verifyEmailError: {},
            time: {}, seconds: 30,
            otpLoader : false
        }
        this.timer = 0;
    }


    secondsToTime = (secs) => {
        const hours = `0${Math.floor(secs / (60 * 60))}`;

        const divisor_for_minutes = secs % (60 * 60);
        const minutes = `0${Math.floor(divisor_for_minutes / 60)}`;

        const divisor_for_seconds = divisor_for_minutes % 60;

        const seconds = divisor_for_seconds > 9 ? Math.ceil(divisor_for_seconds) : `0${Math.ceil(divisor_for_seconds)}`;

        const obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }


    componentDidMount = () => {
        this.props.dispatch(getSocialLoginUrls())
    }
    onChangeText = (value, type) => {

        this.setState({ [type]: value })

        if (type === 'emailId') {
            const obj = {
                "email": value,
            };
            let regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/


            if (value !== "") {
                if (regex.test(value)) {
                    this.props.dispatch(verifyEmail(obj)).then((data) => {
                        if (data.error) {
                            this.setState({ verifyEmailError: data })
                        } else {
                            this.setState({ verifyEmailError: data })
                        }
                    })
                } else {
                    this.setState({ verifyEmailError: { "error": true, "message": "Enter a valid email" } })
                }
            } else {
                this.setState({ verifyEmailError: {} })
            }

        }
    }

    onChangeMobile = (value) => {
        this.setState({ phoneNumber: value })

        const obj = {
            "mobile": `+${value}`,
        };
        if (this.state.phoneNumber !== value) {
            if (value && (value.length >= 11) && (value.length <= 12)) {
                this.props.dispatch(verifyMobile(obj)).then((data) => {
                    if (data.error) {
                        this.setState({ verifyMobileError: data })
                    } else {
                        this.setState({ verifyMobileError: data })
                    }
                })
            } else {
                this.setState({ verifyMobileError: {} })
            }
        }
    }



    onChangeOtp = (value, type) => {
        this.setState({ [type]: value })
    }


    requestOTP = () => {
        this.setState({ otpLoader: true });
        if (this.state.mobileNumber.match(/\d/g).length < 11) {
            this.setState({ otpLoader: false });
            alert('please enter valid mobile number with country code');
        } 
        else if (!this.state.mobileNumber) {
            this.setState({ otpLoader: false });
            alert('please enter valid mobile number with country code');
        }else {
            const num = encodeURIComponent(`+${this.state.mobileNumber}`)
            this.props.dispatch(requestOTP(num)).then(result => {
                if (result) {
                    this.setState({ otpGenerated: true , otpLoader: false });
                    const timeLeftVar = this.secondsToTime(this.state.seconds);
                    this.setState({ time: timeLeftVar });
                    this.timer = setInterval(this.countDown, 1000);
                } else {
                    this.setState({ otpGenerated: false , otpLoader: false });
                }
            })
        }
    }

    loginWithOTP = () => {
        const numotp = encodeURIComponent(`+${this.state.mobileNumber}`)
        this.props.dispatch(loginWithOTP(numotp, this.state.otp)).then((success) => {
            if (success) {
                this.props.handleClose()
            }
        })
    }

    signupWithOTP = () => {
        const numotp = encodeURIComponent(`+${this.state.phoneNumber}`)
        this.props.dispatch(loginWithOTP(numotp, this.state.otp));
    }

    signUp = () => {
        const obj = {
            "fullName": this.state.fullName,
            "phoneNumber": `+${this.state.phoneNumber}`,
            "email": this.state.emailId
        };
        this.props.dispatch(userSignup(obj)).then(userId => {
            if (userId) {
                if (this.state.isTradeGroupUser) {
                    localStorage.setItem('trade-user', JSON.stringify({
                        "userType": this.state.userType,
                        "companyName": this.state.companyName,
                        "companyAddress1": this.state.companyAddress1,
                        "companyAddress2": this.state.companyAddress2,
                        "city": this.state.city,
                        "state": this.state.state,
                        "zipcode": this.state.zip,
                        "website": this.state.website,
                        "comments": this.state.comments,
                        "name": this.state.fullName,
                        "email": this.state.emailId
                    }));
                } else {
                    localStorage.setItem('trade-user', JSON.stringify({
                        "name": this.state.fullName,
                        "email": this.state.emailId,
                        "mobile": `+${this.state.phoneNumber}`
                    }));
                }
                this.setState({ otpGenerated: true });
                this.setState({ verifyMobileError: {}, verifyEmailError: {} })
            } else {
                this.setState({ otpGenerated: false });
            }
        })
    }
    signWithOTP = () => {
        const numotp = encodeURIComponent(`+${this.state.mobileNumber}`)
        this.props.dispatch(loginWithOTP(numotp, this.state.otp));
    }

    handleUserLogin = (provider, profileObj, tokenObj) => {
        const socialLogin = this.props.socialLoginUrls.find(loginObj => loginObj.name === provider);
        if (socialLogin) {
            let promise;
            this.setState({ loading: true });
            setTimeout(() => {
                if (provider === 'Google') {
                    promise = this.props.dispatch(apiLogin(socialLogin.name, socialLogin.callBackUrl, tokenObj.id_token, profileObj));
                } else if (provider === 'Facebook') {
                    promise = this.props.dispatch(apiLogin(socialLogin.name, socialLogin.callBackUrl, tokenObj.accessToken, profileObj));
                }
                promise.then(() => {
                    this.setState({ loading: false });
                })
            }, 2000)

        }
    }

    renderTradeGroupInfo = () => {
        return <div>
            {/* <h2>Advantages of a verified Trade Group user</h2>
            <Divider />
                <p>A verified trade group member will get huge discounts.</p>
                <p>In order to see trade pricing, you must first be verified as a trade member.</p>
                <p>Eligible trade members are Designers, Stagers, Builders, or Realtors.</p>
                <p>If you wish to get verified now, please your company details.</p>
            <Divider /> */}
            <h2>Advantages of being a verified trade group user:</h2>
            <ul>
                <li>Staging Depot business was created to help industry professionals stage vacant homes.</li>
                <li>Only verified trade group members are eligible for, and able to see, in-the-trade pricing.</li>
                <li>Eligible trade members include Home Stagers, Realtors, Designers, and Builders.</li>
                <li>If you wish to get verified now, please enter your company details.</li>
                <li>We strive to get you confirmed same day.</li>
            </ul>
        </div>
    }

    resendOtp = () => {
        this.setState({
            seconds: 30
        });
        this.requestOTP()
    }
    countDown = () => {
        // Remove one second, set state so a re-render happens.
        const seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
        }

    }

    render() {
        const disallowEmail = (this.state.emailId === "") ? true : (this.state.verifyEmailError && this.state.verifyEmailError.error) === false ? false : true
        const disallowMobile = (this.state.phoneNumber === "") ? true : (this.state.verifyMobileError && this.state.verifyMobileError.error) === false ? false : true
        const disallow = disallowEmail || disallowMobile
        return (
            <React.Fragment>
                <div className="signin-singup">
                    <Row gutter={[12, 20]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <img src={require('../../assets/images/signin-signup.webp')} alt="Signin Singup" />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <div className="title">
                                <h1>Welcome !</h1>
                            </div>
                            <Tabs defaultActiveKey="signin">
                                <TabPane tab="Sign in" key="signin">
                                    <p>We will send you a verification code to your registered <b>phone number and email address</b></p>
                                    <form>
                                        <div className="form-fields">
                                            <PhoneInput
                                                country={'us'}
                                                disabled={this.state.otpGenerated}
                                                style={{ width: '100%' }}
                                                onlyCountries={['us', 'in']}
                                                value={this.state.mobileNumber}
                                                onChange={mobileNumber => this.setState({ otpGenerated: false, mobileNumber })}
                                            />
                                        </div>
                                        {this.state.otpGenerated && <div className="form-fields">
                                            <InputBox
                                                placeholder="Enter OTP"
                                                id="otp"
                                                value={this.state.otp}
                                                onChangeText={this.onChangeOtp}
                                                type="text"
                                            />
                                        </div>}
                                        {!this.state.otpGenerated && <div className="form-fields">
                                            <Button type="primary" onClick={this.requestOTP} loading={this.state.otpLoader}>
                                                Request Login code
                                            </Button>
                                        </div>}
                                        {this.state.otpGenerated &&
                                            <div className="form-fields">
                                                <Button type="primary" onClick={this.loginWithOTP}>
                                                    Login
                                                </Button>
                                                <div>
                                                    <span style={{ display: "flex", justifyContent: 'center', color : 'green', marginTop : '10px' }}>OTP sent successfully. Please check your phone number or email</span>
                                                    <span style={{ display: "flex", justifyContent: 'center', fontSize: 40 }}>{this.state.time.m}:{this.state.time.s}</span>
                                                    <span style={{ display: "flex", justifyContent: 'center', }}>
                                                        <div>Didn't receive otp?
                                                            <span style={{ marginLeft: '1rem' }}>
                                                                {
                                                                    this.state.seconds > 0 ?
                                                                        <a disabled={true}>Resend Now</a>
                                                                        :
                                                                        <a style={{ marginLeft: "5px", color: "red" }}
                                                                            onClick={this.resendOtp}
                                                                            disabled={this.state.seconds > 0 ? true : false}>
                                                                            Resend Now
                                                                        </a>
                                                                }
                                                            </span>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>}

                                        <p>With Staging Depot, you don't have to remember a password! Just enter your mobile to get a one time password and type in the code</p>
                                    </form>
                                    {/* <Divider>or Continue Using</Divider>
                                    { !this.state.loading ? <div className="social-login">
                                        <GoogleLogin
                                            render={ (props) =>
                                                <Button onClick={props.onClick} type="secondary" className="google"><img src={require('../../assets/images/icons/google.svg')} alt="Google signin" />  Google</Button>
                                            }
                                            redirectUri={window.location.href}
                                            fetchBasicProfile
                                            clientId="394725245426-d2884dccp4fcprqtcb7ndlsgc80c514c.apps.googleusercontent.com"
                                            buttonText="Login"
                                            onSuccess={(response) => { 
                                                this.handleUserLogin('Google', response.profileObj, response.tokenObj);
                                            }}
                                            onFailure={(response) => { 
                                                this.props.dispatch({ type: UtilsType.SET_NOTIFICATION, notification: { type: 'ERROR', message: "Login using Google failed." }})
                                            }}
                                            cookiePolicy={'single_host_origin'}
                                        /> 
                                        <FacebookLogin
                                            appId="571424233901206"
                                            callback={(response) => {
                                                if(response && response.accessToken) {
                                                    this.handleUserLogin('Facebook', { name: response.name }, { accessToken: response.accessToken });
                                                } else {
                                                    this.props.dispatch({ type: UtilsType.SET_NOTIFICATION, notification: { type: 'ERROR', message: "Login using Facebook failed." }})
                                                }
                                            }}
                                            render={props => (
                                                <Button onClick={props.onClick} type="secondary" className="facebook"><img src={require('../../assets/images/icons/facebook.svg')} alt="Facebook signin" />  Facebook</Button>
                                            )}
                                        /> */}
                                    {/* </div> : <div className="social-login"><Spin tip="Signing in you. Please wait." /></div> } */}
                                </TabPane>
                                <TabPane tab="Sign up" key="signup">

                                    <form>
                                        <div className="form-fields">
                                            <InputBox
                                                placeholder="Enter Full Name"
                                                id="fullName"
                                                disabled={this.state.otpGenerated}
                                                value={this.state.fullName}
                                                onChangeText={this.onChangeText}
                                                type="text"
                                            />
                                        </div>
                                        <div className="form-fields">
                                            <InputBox
                                                placeholder="Enter Email"
                                                id="emailId"
                                                disabled={this.state.otpGenerated}
                                                value={this.state.emailId}
                                                onChangeText={this.onChangeText}
                                                type="email"
                                            />
                                            <div>
                                                <span style={{ color: (this.state.verifyEmailError && this.state.verifyEmailError.error === true) ? "red" : "green" }}>
                                                    {this.state.verifyEmailError && this.state.verifyEmailError.message}</span>
                                            </div>

                                        </div>
                                        <div className="form-fields">
                                            <PhoneInput
                                                country={'us'}
                                                style={{ width: '100%' }}
                                                onlyCountries={['us', 'in']}
                                                disabled={this.state.otpGenerated}
                                                value={this.state.phoneNumber}
                                                // onChange={phoneNumber => this.setState({ phoneNumber })}
                                                onChange={phoneNumber => this.onChangeMobile(phoneNumber)}
                                            />
                                            <div>
                                                <span style={{ color: (this.state.verifyMobileError && this.state.verifyMobileError.error === true) ? "red" : "green" }}>
                                                    {this.state.verifyMobileError && this.state.verifyMobileError.message}</span>
                                            </div>
                                        </div>
                                        <label>
                                            <Checkbox
                                                checked={this.state.isTradeGroupUser}
                                                onChange={(e) => {
                                                    this.setState({ isTradeGroupUser: e.target.checked });
                                                }}
                                            />
                                            &nbsp;Sign up as tradegroup user. <Popover
                                                content={this.renderTradeGroupInfo}
                                                trigger="click"
                                                visible={this.state.tradeGroupHelpVisible}
                                                onVisibleChange={(tradeGroupHelpVisible) => this.setState({ tradeGroupHelpVisible })}
                                            >
                                                <i className="fa fa-info-circle" />
                                            </Popover>
                                        </label>
                                        {
                                            this.state.isTradeGroupUser &&
                                            <div>
                                                <div className="form-fields full-width">
                                                    <SelectBox
                                                        placeholder="User Type"
                                                        id="userType"
                                                        disabled={this.state.otpGenerated}
                                                        value={this.state.userType}
                                                        showSearch={false}
                                                        style={{ width: '100%' }}
                                                        options={[
                                                            { value: "C03", label: "Designer" },
                                                            { value: "C01", label: "Home Stager" },
                                                            { value: "C02", label: "Realtor" },
                                                            { value: "C06", label: "Builder" }
                                                        ]}
                                                        onChangeText={this.onChangeText}
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="form-fields">
                                                    <InputBox
                                                        placeholder="Company Name"
                                                        id="companyName"
                                                        disabled={this.state.otpGenerated}
                                                        value={this.state.companyName}
                                                        onChangeText={this.onChangeText}
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="form-fields">
                                                    <InputBox
                                                        placeholder="Website"
                                                        id="website"
                                                        disabled={this.state.otpGenerated}
                                                        value={this.state.website}
                                                        onChangeText={this.onChangeText}
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="form-fields">
                                                    <InputBox
                                                        placeholder="Address Line1"
                                                        id="companyAddress1"
                                                        disabled={this.state.otpGenerated}
                                                        value={this.state.companyAddress1}
                                                        onChangeText={this.onChangeText}
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="form-fields">
                                                    <InputBox
                                                        placeholder="Address Line2"
                                                        id="companyAddress2"
                                                        disabled={this.state.otpGenerated}
                                                        value={this.state.companyAddress2}
                                                        onChangeText={this.onChangeText}
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="form-fields">
                                                    <InputBox
                                                        placeholder="City"
                                                        id="city"
                                                        disabled={this.state.otpGenerated}
                                                        value={this.state.city}
                                                        onChangeText={this.onChangeText}
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="form-fields">
                                                    <InputBox
                                                        placeholder="State Abbreviation"
                                                        id="state"
                                                        disabled={this.state.otpGenerated}
                                                        value={this.state.state}
                                                        maxLength={2}
                                                        onChangeText={this.onChangeText}
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="form-fields">
                                                    <InputBox
                                                        placeholder="Zip Code"
                                                        id="zip"
                                                        disabled={this.state.otpGenerated}
                                                        value={this.state.zip}
                                                        onChangeText={this.onChangeText}
                                                        type="text"
                                                        maxLength={10}
                                                    />
                                                </div>
                                                <div className="form-fields">
                                                    <InputBox
                                                        placeholder="Please add any pertinent information to help us verify you quickly"
                                                        id="comments"
                                                        disabled={this.state.otpGenerated}
                                                        value={this.state.comments}
                                                        textArea
                                                        onChangeText={this.onChangeText}
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {this.state.otpGenerated && <div className="form-fields">
                                            <div style={{ fontSize: "12px", color: 'red', textAlign: 'right' }}>
                                                <b>
                                                    {
                                                        "Please do not leave screen until you enter code below"
                                                    }
                                                </b>
                                            </div>
                                            <InputBox
                                                placeholder="Enter OTP"
                                                id="otp"
                                                value={this.state.otp}
                                                onChangeText={this.onChangeOtp}
                                                type="text"
                                            />
                                        </div>}
                                        <div className="form-fields">
                                            <Button type="primary" onClick={() => this.setState({ otpGenerated: false, verifyMobileError: {}, verifyEmailError: {} })}>Cancel</Button>
                                        </div>
                                        {!this.state.otpGenerated && <div className="form-fields">
                                            <div className="form-fields">
                                                <Button type="primary" onClick={this.signUp} disabled={this.state.otpGenerated || disallowEmail || disallowMobile}>Continue</Button>
                                            </div>
                                        </div>}
                                        {this.state.otpGenerated &&
                                            <div className="form-fields">
                                                <Button type="primary" onClick={this.signupWithOTP}>
                                                    Login
                                                </Button>
                                                <span style={{ display: "flex", justifyContent: 'center', color : 'green', marginTop : '10px' }}>OTP sent successfully. Please check your phone number or email</span>
                                            </div>
                                            }
                                    </form>
                                    <p>We will send you a verification code to your registered <b>phone number and email address</b></p>
                                    <div className="get-otp">
                                        <div className="change-phone-number">

                                        </div>
                                    </div>
                                </TabPane>

                                {/* signin tab ends */}


                                {/* signup tab ends */}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}
function mapStateToProps(state) {
    return {
        socialLoginUrls: state.login.socialLoginUrls
    }
}
export default connect(mapStateToProps)(withRouter(SigninSignup))