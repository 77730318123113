import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import './QuickLinks.scss';

class QuickLinks extends React.Component {

    render() {
        return (
            <React.Fragment>

                {/* page banners */}
                <div className="about-quick-links">
                    <ul>
                        <li>
                            <NavLink to="/about-us" exact activeClassName="active">About Us</NavLink>
                        </li>
                        <li>
                            <NavLink to="/culture" exact activeClassName="active">Culture</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/our-commitment" exact  activeClassName="active">Our Commitment</NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/career" exact activeClassName="active">Career</NavLink>
                        </li>
                    </ul>
                </div>

            </React.Fragment >
        )
    }
}
export default withRouter(QuickLinks)