import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { Row, Col, Button, Collapse } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import '../../OpenOrders/OpenOrders.scss';
import { connect } from 'react-redux';
import { getOpenOrderList, updateOpenOrder } from '../../../Actions/OrderActions';

const { Panel } = Collapse;

export class ExistingOpenOrders extends Component {

    componentDidMount() {
        let isDatacontext = sessionStorage.getItem('itop-user-context') ? true : false
        if (isDatacontext === true) {
            this.props.dispatch(getOpenOrderList())
        }
    }

    handleUpdateOpenOrder = (data) => {
        let items = []

        data.items.forEach((item) => {
            items.push({ "matcode": item.maT_CODE, "srno" : item.iteM_NO, "qty": Number(item.qty.split(".")[0]), "room": item.room })
        })
 
        this.props.cartItems.forEach((item, index) => {
            var sam = items.filter(v => v.matcode === item.matCode)[0]
            if(sam !== undefined){
                sam.qty = item.quantity + sam.qty;
            }
            else {                
                items.push({ "matcode": item.matCode, "srno" : data.items.length + 1, "qty": Number(item.quantity), "room": item.room })
            }
        })

        // this.props.cartItems.forEach((item, index) => {
        //     const sam = data.items.filter(v => v.maT_CODE === item.matCode)[0]
        //     if(sam !== undefined){
        //         items = [...items, { "matcode": item.matCode, "srno" : sam.iteM_NO, "qty": item.quantity + Number(sam && sam.qty.split(".")[0]) }]
        //     }
        //     else {
        //         items = [...items, { "matcode": item.matCode, "srno" : data.items.length + 1, "qty": Number(item.quantity) }]
        //     }

        // })

        let model = {
            "orderNo": data && data.ordeR_NO,
            "customerId": 0,
            "addressType": data.addressDetails && data.addressDetails.addressType,
            "items": items
        }

        this.props.dispatch(updateOpenOrder(model))
        this.props.handleClose()

    }

    render() {
        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <section className="open-orders">
                            <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <h2 className="margin-bottom20">Draft Orders</h2>
                                    {
                                        !this.props.allOpenOrders.length ? "No draft orders found" :
                                            <div className="open-orders-list">
                                                <Collapse
                                                    defaultActiveKey={['1']}
                                                    expandIconPosition='right'
                                                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                                                    ghost
                                                >
                                                    {
                                                        this.props.allOpenOrders && this.props.allOpenOrders.map((list, index) =>
                                                            <Panel header={`Draft order - ${list.ordeR_NO}`} key={index + 1}>
                                                                <div className="address-details">
                                                                    <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                                                            <p>Address Details :</p>
                                                                        </Col>
                                                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                                                        <div>{list.addressDetails?.address1}</div>
                                                                        <div>{list.addressDetails?.address2}</div>
                                                                        <div>{list.addressDetails?.city}&nbsp;&nbsp; {list.addressDetails?.state}&nbsp;&nbsp;{list.addressDetails?.pincode}</div>
                                                                        </Col>
                                                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 14 }} lg={{ span: 14 }} className="text-right">
                                                                            <h2>Would you like to append cart items to this Draft order?</h2>
                                                                            <Button type="primary" onClick={() => this.handleUpdateOpenOrder(list)}>Update Draft order</Button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>

                                                                <div className="orders-containers">
                                                                    {
                                                                        list.items && list.items.map(item =>
                                                                            <div className="ordered-items">
                                                                                <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                                                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                                                        <img src={item.imagePath} style={{ maxWidth: '100%' }} alt="Valentino Armchair in Teak Finish" />
                                                                                    </Col>
                                                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 20 }}>
                                                                                        <h4>{item.maT_DESC}</h4>
                                                                                        <p>{item.remark1}</p>
                                                                                        <div className="specfications">
                                                                                            <div className="rent-price">
                                                                                                <h5>Rent</h5>
                                                                                                <span>${item.monthlyRent}/mon</span>
                                                                                            </div>
                                                                                            {/* <div className="color">
                                                                                                <h5>Color</h5>
                                                                                                <span>{item.remark3}</span>
                                                                                            </div> */}
                                                                                            <div className="quantity">
                                                                                                <h5>Quantity</h5>
                                                                                                <span>{parseInt(item.qty)}</span>
                                                                                            </div>
                                                                                            <div className="room">
                                                                                                <h5>Room</h5>
                                                                                                <span>{item.room}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </Panel>
                                                        )
                                                    }
                                                </Collapse>
                                            </div>
                                    }
                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

function mapStateToProps(state) {
    return {
        allOpenOrders: state.orders.getAllOpenOrders.orderData,
        openordersLoaded: state.orders.getAllOpenOrders.isFetching
    }
}

export default connect(mapStateToProps)(withRouter(ExistingOpenOrders))

