import { Col, Row, Spin } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FurnitureTypes } from '../../Actions/ActionType';
import { getFurnitureGroups, getFurnitureListing, getFurnitureSubGroups, resetAllFilters } from '../../Actions/FurnitureActions';
import BreadCrumbs from '../../Components/BreadCrumbs/BreadCrumbs';
import FurnitureItem from '../../Components/Furniture/FurnitureItem';
import SelectBox from '../../Components/SelectBox/SelectBox';
import FurnitureFilter from './FurnitureFilter/FurnitureFilter';
import './FurnitureListing.scss';

class FurnitureListing extends React.Component {
    constructor() {
        super()
        this.state = {
            sortBy: '',
            defaultActiveKey: [],
            loading: false,
            furnitureList: [],
            filterData: [],
            page: 0
        }
    }
    componentDidMount() {
        let groupid = null;
        let search = null;
        if (this.props.match.params.groupid) {
            groupid = this.props.match.params.groupid;
        } else if(this.props.match.params.search) {
            search = this.props.match.params.search;
        } else {
            this.props.history.push('/furniture')
        }
        this.setState({ loading: true })
        const furnitureCall = this.props.dispatch(getFurnitureListing(search, this.props.userLocation ? this.props.userLocation.ziP_CODE : null, groupid));
        const subGroupsCall = this.props.dispatch(getFurnitureSubGroups());
        if (this.props.groups && this.props.groups.length === 0) {
            this.props.dispatch(getFurnitureGroups());
        }
        Promise.all([subGroupsCall, furnitureCall]).then(() => {
            this.setState({ loading: false });
        });
    };

    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }

    refreshMaterialList = (key) => {
        if (key.length > 0 && key[1] !== this.props.match.params.groupid) {
            const group = this.props.groups.find(grp => grp.matgrouP_CODE === key[1]);
            this.props.dispatch({ type: FurnitureTypes.CLEAR_SUBGROUPS })
            if (group) {
                this.props.dispatch(getFurnitureListing(null, this.props.userLocation ? this.props.userLocation.ziP_CODE : null, group.matgrouP_CODE));
                this.props.history.push(`/furniture-listing/${this.props.match.params.type ? this.props.match.params.type : 'room'}/${group.matgrouP_CODE}/${group.description}`)
            }
        }
    }

    getFilteredGroups = () => {
        const filterTypes = {'1': 'room', '2': 'furniture'};
        if (this.props.match.params.type) {
            return this.props.groups.filter(grp => this.props.match.params.type === filterTypes[grp.grouP_ID])
        } else {
            return this.props.groups.filter(grp => 'room' === filterTypes[grp.grouP_ID])
        }
    }
    loadMoreItems = (page) => {
        this.setState({ page });
    }
    render() {
        const { furnitureList, customerInfo, allFilters } = this.props;
        const userId = customerInfo && customerInfo.user_ID ? customerInfo.user_ID.toString() : undefined;
        let categorryType = []
        if(this.props.match.params.groupid){
            let getGroup = this.props.subGroups.filter(a => a.matgrouP_CODE === this.props.match.params.groupid);
            categorryType = furnitureList.filter(el => {
                return getGroup.find(element => {
                    return element.matsubgrouP_CODE === el.matsubgrouP_CODE;
                });
            });
        }
        else
        {
            categorryType = furnitureList;
        }
        let data = [];
        let filteredDataTemp = categorryType.filter(f => {
            if(allFilters.productAvailable && !f.showavailabilty) {
                return false;
            }
            if(allFilters.subGroups.length > 0 && !allFilters.subGroups.includes(f.matsubgrouP_CODE)){
                return false;
            } 
            if(allFilters.priceRange > 0 && f.monthlyrent > allFilters.priceRange){
                return false;
            }
            return true;
        });
        let filteredData = filteredDataTemp.filter(f => {
            if(allFilters.depotInventory && f.stagingowner) {
                return true;
            } 
            if(allFilters.coopInventory && f.stagingowner === 0 && f.coop === 'Y') {
                return true;
            }
            if(allFilters.myInventory && userId && f.owneR_ID === userId) {
                return true;
            }
            return false;
        })
        data.push(...filteredData);

        if (this.state.sortBy === 'rent-low-to-high') {
            data = data.sort((a,b) => (a.monthlyrent > b.monthlyrent) ? 1 : ((b.monthlyrent > a.monthlyrent) ? -1 : 0))
        } else if (this.state.sortBy === "rent-high-to-low") {
            data = data.sort((a,b) => (a.monthlyrent < b.monthlyrent) ? 1 : ((b.monthlyrent < a.monthlyrent) ? -1 : 0))
        }
        const originalData = [...data];
        data = data.slice(0, (this.state.page * 10 ) + 10)
        return (
            <React.Fragment>

                {/* page banners */}
                <section className="page-banners furniture">
                    <div className="container mx-auto">
                        <h1>Furniture</h1>
                        <p>Our Furniture Includes Luxurious Chairs, Sturdy Desks, Chic Wardrobes, And Comfortable Beds.</p>
                        <p>You Will Find Pre-Selected, Tasteful Furniture For Your Kitchen, Living Room, Dining Room, And Bedroom. </p>
                    </div>
                </section>
                {/* page banners */}

                <section className="inner-pages top-right-wave">
                    <div className="container mx-auto">
                        <BreadCrumbs {...this.props} navigations={[{ name: 'Home', link: '/' }, { name: 'Furniture', link: '/furniture' }, { name: this.props.match.params.groupname ? this.props.match.params.groupname : (this.props.match.params.search ? this.props.match.params.search : 'list'), link: '/furniture-listing' }]} />
                        {/* BreadCrumbs and filter ends */}

                        <Row gutter={[{ xs: 8, sm: 20, md: 30, lg: 40 }, { xs: 8, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 7 }} lg={{ span: 7 }}>
                                <FurnitureFilter handleRefresh={this.refreshMaterialList} groupid={this.props.match.params.groupid} type={this.props.match.params.type} groups={this.getFilteredGroups()} subGroups={this.props.subGroups} />
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 17 }} lg={{ span: 17 }}>

                                {
                                    this.state.loading && <Spin tip={"You are going to see Awesome furniture. Please wait.."} />
                                }
                                {/* title and filters */}
                                <Row gutter={[8, 8]}>
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 16 }} lg={{ span: 16 }}>
                                        <h2>Products List</h2>
                                    </Col>
                                    {
                                        this.props.match.params.groupname === "OUTDOOR" && <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 16 }} lg={{ span: 16 }}>
                                            <p>Check out the Bundles section for outdoor furniture packages.</p>
                                        </Col>
                                    }
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <div className="sort-by">
                                            <SelectBox
                                                label="Sort By"
                                                id="sortBy"
                                                value={this.state.sortBy}
                                                options={[{ value: "", label: "Choose" }, { value: "rent-low-to-high", label: "Rent Low to High" }, { value: "rent-high-to-low", label: "Rent High to Low" }]}
                                                onChangeText={this.onChangeText}
                                                optional="true"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {!this.state.loading && this.props.furnitureList.length === 0 && <div className="no-product-available">
                                    <Row gutter={[8, 8]}>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                            <h3>No Products Available !</h3>
                                            <p>Looks like no products are available in this category.</p>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <img src={require('../../assets/images/icons/box.svg')} alt="No Products Available" />
                                        </Col>
                                    </Row>
                                </div>}
                                {/* furniture listings */}
                                
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={(page) => this.loadMoreItems(page)}
                                    hasMore={data.length < originalData.length}
                                    loader={<div className="loader" key={0}></div>}
                                >
                                    <Row gutter={[10, 10]}>
                                
                                    {
                                        // this.state.furnitureList.map(item => <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        data && data.map(item => <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 6 }}>
                                                <FurnitureItem item={item} />
                                        </Col>)
                                    }
                                
                                    </Row>
                                </InfiniteScroll>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment >
        )
    }
}
function mapStateToProps(state) {
    return {
        furnitureList: state.furniture.furnitureList,
        subGroups: state.furniture.furnitureSubGroups,
        groups: state.furniture.furnitureGroups,
        allFilters: state.furniture,
        userLocation: state.users.userLocation,
        customerInfo: state.users.customerInfo
    }
}
export default connect(mapStateToProps)(withRouter(FurnitureListing));