import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux'
import { Row, Col, Button, Select } from 'antd';
import '../MyDashboard.scss';
import SiderMenu from '../SiderMenu';
import InputBox from '../../../Components/InputBox/InputBox';
import { getMyInventory,manageCOOP } from '../../../Actions/FurnitureActions';
import SelectBox from '../../../Components/SelectBox/SelectBox';

class MyInventory extends React.Component {
    constructor() {
        super()
        this.state = {
            searchText: '',
            filterCatgory: 'All',
            filterGroup: 'All'
        }
    }
    onChangeText = (value, type) => {
        this.setState({ [type]: value })
    }
    componentDidMount() {
        this.props.dispatch(getMyInventory())
    }
    manageCoop(productID,flag) {
        this.props.dispatch(manageCOOP({productID,flag}))
    }
    setFilters = (name, value) => {
        this.setState({ [name]: value });
    }
    render() {
        const groupOptions = ['All'];
        const categoryOptions = ['All'];
        this.props.myInventory && Array.isArray(this.props.myInventory) && this.props.myInventory.forEach(element => {
            !groupOptions.includes(element.matgrouP_DESC) && groupOptions.push(element.matgrouP_DESC);
            !categoryOptions.includes(element.matsubgroup) && categoryOptions.push(element.matsubgroup);
        });
        const myInventory = [];
        this.props.myInventory.forEach(item => {
            if (this.state.filterGroup !== 'All' && item.matgrouP_DESC !== this.state.filterGroup) {
                return false;
            } else if (this.state.filterCatgory !== 'All' && item.matsubgroup !== this.state.filterCatgory) {
                return false;
            }
            myInventory.push(item); 
        })
        return (
            <React.Fragment>
                <div className="inner-pages padding-40 top-right-wave light-bg">
                    <div className="container mx-auto">
                        <div className="my-dashboard margin-bottom20">
                            <h1>My Dashboard</h1>
                        </div>
                        <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                <SiderMenu />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }} lg={{ span: 19 }}>
                                <div className="card my-orders light-color">
                                    <Row>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                            <h2>My Inventory</h2>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                            <div className="form-fields">
                                                <Select
                                                    placeholder="Filter by Group"
                                                    style={{ width: '90%' }}
                                                    value={this.state.filterGroup}
                                                    onChange={value => this.setFilters('filterGroup', value)}
                                                    options={groupOptions.map(group => (group === 'All' ? { label: 'All Groups', value: group } : { label: group, value: group })) }
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                            <div className="form-fields">
                                                <Select
                                                    placeholder={"Filter by Category"}
                                                    style={{ width: '90%' }}
                                                    options={categoryOptions.map(group => (group === 'All' ? { label: 'All Categories', value: group } : { label: group, value: group })) }
                                                    value={this.state.filterCatgory}
                                                    onChange={value => this.setFilters('filterCatgory', value)}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                            <div className="form-fields">
                                                <InputBox
                                                    placeholder="Search by Product name or order no."
                                                    id="searchText"
                                                    value={this.state.searchText}
                                                    onChangeText={this.onChangeText}
                                                    type="text"
                                                    optional="true"
                                                    suffix={"fa fa-search"}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    {/* list starts here */}
                                    <div className="orders-containers">
                                        {
                                            myInventory.length === 0 && 
                                            <div className="no-product-available">
                                                <Row gutter={[8, 8]}>
                                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>
                                                        <h4>{this.props.myInventory.length === 0 ? "You don't have your own inventory !" : "No inventory found matching with filter criteria." }</h4>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                        {
                                            myInventory.map(item => (!this.state.searchText || item.maT_DESC.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) && <div className="card">
                                            <div className="ordered-items">
                                                <Row gutter={[{ xs: 10, sm: 15, md: 15, lg: 20 }, { xs: 10, sm: 16, md: 10, lg: 10 }]}>
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                        <img style={{ maxWidth: '100%' }} src={item.thumbnailImagePath} alt={item.maT_DESC} />
                                                    </Col>
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
                                                        <h4>{item.maT_DESC}</h4>
                                                        <p>{item.remark1}</p>
                                                        <div className="specfications">
                                                            <div className="added-on">
                                                                <h5>Group</h5>
                                                                <span>{item.matgrouP_DESC}</span>
                                                            </div>
                                                            <div className="added-on">
                                                                <h5>Category</h5>
                                                                <span>{item.matsubgroup}</span>
                                                            </div>
                                                            <div className="quantity">
                                                                <h5>Quantity</h5>
                                                                <span>{item.qty}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {item.coop == 'Y'?
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                    <div className="action-buttons">
                                                        <Button type="secondary" onClick ={()=>this.manageCoop(item.maT_CODE,false)}>Remove from COOP</Button>
                                                    </div>
                                                </Col>:
                                                
                                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                        <div className="action-buttons">
                                                            <Button type="secondary" onClick={() => this.manageCoop(item.maT_CODE,true)}>Add to COOP</Button>
                                                        </div>
                                                    </Col>
                                                    }
                                                </Row>
                                            </div>
                                            {/* list ends here */}
                                        </div>)
                                        }
                                    </div>
                                    {/* list ends here */}

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

function mapStateToProps(state) {
    return {
        myInventory: state.furniture.myInventoryList
    }
}
export default connect(mapStateToProps)(withRouter(MyInventory))