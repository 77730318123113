import React from 'react';
import { withRouter } from 'react-router';
import SimpleImageSlider from 'react-simple-image-slider';

class ProductSldier extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        const images = this.props.images.map(item => ({url: item.imagePath }));
        return (
            <React.Fragment>
                {/* <!--product Slider section starts--> */}
                <div className="product-slider">
                    {/* <!--Slider Item starts--> */}
                    {/* <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        arrows={false}
                        fade={true}
                    >
                        {
                            this.props.images && this.props.images.map(image => {
                                return <div className="item">
                                    <img src={image.imagePath} alt="" />
                                </div>
                            })
                        }
                    </Slider> */}
                    <SimpleImageSlider
                        width={450}
                        height={400}
                        images={images}
                        showBullets={true}
                        showNavs={true}
                    />

                </div>
                {/* <!--product Slider section ends--> */}
            </React.Fragment>

        )
    }
}
export default withRouter(ProductSldier)