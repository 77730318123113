import { Button, Layout, Result } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { OrderTypes } from '../Actions/ActionType';
import { checkOfflineCart, loadCartItems } from '../Actions/CartActions';
import { placeOrder } from '../Actions/OrderActions';
import { setAuthorizationToken } from '../Actions/setAuthorizationToken';
import { getCustomerInfo, getCustomerDetails, getLocations, createTradeGroupUser, getTradeCustomer } from '../Actions/UserActions';
import { getWishListDetails } from '../Actions/WishListActions';
import ModalBox from '../Components/ModalBox/ModalBox';
import SelectBox from '../Components/SelectBox/SelectBox';
import FooterNav from './Footer';
import HeaderNav from './Header';

const { Content } = Layout;

class Layouts extends React.Component {
  state = {
    collapsed: false,
    openOrder: ''
  };

  componentDidMount() {
    if (this.props.locations.length === 0) {
      this.props.dispatch(getLocations());
    }
    if (this.props.context) {
      if (this.props.cartItems.length === 0)
        this.props.dispatch(loadCartItems());
      this.props.dispatch(getCustomerInfo());
      this.props.dispatch(getCustomerDetails());
      this.props.dispatch(getTradeCustomer());
      this.props.dispatch(createTradeGroupUser());
      this.props.dispatch(checkOfflineCart());
      this.props.dispatch(getWishListDetails(this.props.userLocation ? this.props.userLocation.ziP_CODE : ''))
    }
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentWillReceiveProps(newProps) {
    if (newProps.promptOpenOrders && newProps.cartItems.length > 0) {
      this.props.dispatch({ type: OrderTypes.PROMPT_OPEN_ORDERS_SELECTION, payload: false })
    }
  }

  placeYourOrder = () => {
    const selectedOrder = this.props.openOrders.find(order => order.ordeR_NO === this.state.openOrder);
    this.props.dispatch(placeOrder(this.state.openOrder, false)).then(() => {
        this.props.dispatch({ type: OrderTypes.PROMPT_OPEN_ORDERS_SELECTION, payload: false });
        window.location.href = `/#/my-cart/${selectedOrder.addressDetails.addressType}`;
    })
  };

  render() {
    if(this.props.context && this.props.context.token) {
      setAuthorizationToken(this.props.context.token);
    }
    if (this.props.sessionTimeout) {
      return (
        <React.Fragment>
          <Result
            status="error"
            style={{ margin: "10%" }}
            title="Looks like your session is expired"
            subTitle={`You will have to login again.`}
            extra={[
              <Button
                onClick={() => window.location.href="/"}
                type="primary"
                key="console"
              >
                Login Again
              </Button>
            ]}
          />
        </React.Fragment>
      );
    }
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout className="site-layout">
          <HeaderNav profile={this.props.profile} userContext={this.props.context} {...this.props} />
            
          <Content>
            {this.props.children}
          </Content>
          { this.props.cartItems.length === 0 && this.props.promptOpenOrders && <ModalBox
              title="Want to continue work on a draft order?"
              onCancel={() => this.props.dispatch({ type: OrderTypes.PROMPT_OPEN_ORDERS_SELECTION, payload: false })}
              visible={this.props.promptOpenOrders}
              wrapClassName="main-search-area"
              footer={null}
              destroyOnClose
            >
                <h4>Looks like you have one or more draft orders saved. Choose the one you'd like to work on or click No to start a new order.</h4>
                <div className="inspirations-product-select">
                  <SelectBox
                      style={{ width: '50%' }}
                      placeholder={"Select an order to proceed"}
                      value={this.state.openOrder}
                      options={this.props.openOrders ? this.props.openOrders.map(m => ({ label: m.addressDetails ? `${m.addressDetails.address1} - ${m.addressDetails.city}, ${m.addressDetails.state}  ${m.addressDetails.pincode}` : m.ordeR_NO , value: m.ordeR_NO })) : []}
                      onChangeText={(openOrder) => this.setState({ openOrder })}
                      optional="true"
                  />
                </div>
                
              <div style={{ marginTop: 10 }}>
                <Button className="primary" onClick={() => window.location.reload()}>
                  No
                </Button>{" "}
                &emsp;
                <Button
                  className="primary"
                  disabled={!this.state.openOrder}
                  onClick={() => this.placeYourOrder()}
                >
                  Yes
                </Button>
              </div>
            </ModalBox> }
          <FooterNav />
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.login.profile,
    context: state.login.context,
    locations: state.users.locations,
    cartItems: state.cart.cartItems,
    userLocation: state.users.userLocation,
    customerInfo: state.users.customerInfo,
    promptOpenOrders: state.orders.promptOpenOrderSelection,
    openOrders: state.orders.getAllOpenOrders.orderData,
    sessionTimeout: state.login.sessionTimeout
  }
}

export default connect(mapStateToProps)(Layouts)